import { IconButton } from '@mui/material'
import {
  BarChart,
  TableChart,
  GetApp as DownloadIcon,
} from '@material-ui/icons'
import { memo } from 'react'
import AnalyticsChartCard from './ChartCard'
import AnalyticsNumberCard, { AnalyticsNumberCardProps } from './NumberCard'

const AnalyticsNumberChartCard: React.FC<
  AnalyticsNumberCardProps & { enabled: boolean; toggle: () => void }
> = ({
  title,
  description,
  number,
  tableData,
  enabled,
  toggle,
  onDownload,
}) => {
  const chartData = {
    label: title,
    data:
      tableData?.map((d) => ({
        label: d.label,
        value: typeof d.value === 'string' ? parseInt(d.value, 10) : d.value,
      })) ?? [],
  }

  return (
    <div className="position-relative">
      {enabled && (
        <AnalyticsNumberCard
          title={title}
          description={description}
          number={number}
          tableData={tableData}
        />
      )}
      {!enabled && (
        <AnalyticsChartCard title={title} type="bar" chartData={chartData} />
      )}
      {onDownload && (
        <IconButton
          onClick={onDownload}
          className="position-absolute top-0 right-5"
        >
          <DownloadIcon />
        </IconButton>
      )}
      <IconButton
        aria-label="switch"
        onClick={toggle}
        className="position-absolute top-0 right-0"
      >
        {enabled && <BarChart />}
        {!enabled && <TableChart />}
      </IconButton>
    </div>
  )
}

export default memo(AnalyticsNumberChartCard)

import { Button, Card, Grid, Typography } from '@mui/material'
import Loader from 'components/Loader'
import useAnalyticsData from 'hooks/useAnalyticsData'
import { memo, useState } from 'react'
import AnalyticsCarouselCard from './CarouselCard'
import AnalyticsChartCard from './ChartCard'
import AnalyticsMapCard, { StateConfig } from './MapCard'
import AnalyticsNumberCard from './NumberCard'
import AnalyticsNumberPieCard from './NumberPieCard'
import Gradient from 'javascript-color-gradient'
import useStates from 'hooks/useStates'
import produce from 'immer'
import AddStateDialog from 'components/AddStateDialog'
import AddNutritionStateDialog from 'components/AddStateDialog/nutrition'
import { AddLocation } from '@material-ui/icons'
import { GetApp as DownloadIcon } from '@material-ui/icons'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Permissions } from 'containers/Core/utils'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import NumberChartCard from './NumberChartCard'
import { alphabetize } from 'utils/sort'
import { computeTotal, percentage } from 'utils/analytics'
import ExportDataDialog from 'components/ExportDataDialog'

import './styles.scss'
import useEligibleInsurances from 'hooks/useEligibleInsurance'
import { Parser } from '@json2csv/plainjs'
import { transpose } from 'csv-transpose'
import { handleDownloadCSV } from 'utils/csv'

const Analytics: React.FC = () => {
  const { permissions } = useAuthContext()
  const { states: allStates } = useStates()
  const { insurances } = useEligibleInsurances()
  const { endDate, startDate, analyticsData, setEndDate, setStartDate } =
    useAnalyticsData()
  const [openAddState, setOpenAddState] = useState<boolean>(false)
  const [openExportData, setOpenExportData] = useState<boolean>(false)
  const [openAddNutritionState, setOpenAddNutritionState] =
    useState<boolean>(false)
  const [switches, setSwitches] = useState<{ [key: string]: boolean }>({
    waitlist: true,
    direct: true,
    previouslyDiagnosed: true,
    labWork: true,
    howDidYouHear: false,
    reason: true,
    primaryGoal: true,
    acne: true,
    hasAnxiety: true,
    birthControl: true,
    hairGrowth: true,
    hormonesTested: true,
    periodRegularity: true,
    ttc: true,
    weight: true,
    hasInsurance: true,
    inControl: false,
    diagnosed: true,
    howInformed: true,
    conditions: true,
    treatments: true,
  })

  const toggleSwitch = (key: string) => {
    const newSwitches = produce((draftSwitches) => {
      draftSwitches[key] = !draftSwitches[key]
    }, switches)
    setSwitches(newSwitches)
  }

  const openAddStateDialog = () => setOpenAddState(true)
  const closeAddStateDialog = () => setOpenAddState(false)
  const openExportDataDialog = () => setOpenExportData(true)
  const closeExportDataDialog = () => setOpenExportData(false)
  const openAddNutritionStateDialog = () => setOpenAddNutritionState(true)
  const closeAddNutritionStateDialog = () => setOpenAddNutritionState(false)
  const handleStartDateChange = (date: Date | null) => setStartDate(date)
  const handleEndDateChange = (date: Date | null) => setEndDate(date)

  if (!analyticsData || !permissions.length) {
    return <Loader />
  }

  const {
    user,
    waitlist,
    breakdowns: {
      howDidYouHear,
      states,
      age,
      reason,
      primaryGoal,
      acne,
      hasAnxiety,
      birthControl,
      hairGrowth,
      hormonesTested,
      periodRegularity,
      ttc,
      weight,
      hasInsurance,
      inControl,
      diagnosed,
      howInformed,
      conditions,
      treatments,
      viewHaveInsurance,
      viewPrograms,
      viewPaymentPlans,
      viewPayment,
    },
  } = analyticsData

  const insuranceProviders = waitlist.insurance.reduce((acc, insurance) => {
    acc[insurance.insuranceProvider]
      ? (acc[insurance.insuranceProvider] += insurance.count)
      : (acc[insurance.insuranceProvider] = insurance.count)
    return acc
  }, {} as { [key: string]: number })

  const getStateHeatmapConfig = () => {
    const colorGradient = new Gradient()
    colorGradient.setMidpoint(20)
    colorGradient.setGradient(
      '#F0FFFE',
      '#C0E5FE',
      '#90CCFE',
      '#60B2FE',
      '#3099FE',
      '#007FFE'
    )

    const values = Object.values(states)
    const min = Math.min(...values)
    const max = Math.max(...values)

    const stateConfig: StateConfig = {}

    for (const state of Object.keys(states)) {
      const value = states[state]
      const denominator = max - min === 0 ? 1 : max - min
      const scaledValue = (19 * (value - min)) / denominator + 1
      stateConfig[state] = { fill: colorGradient.getColor(scaledValue) }
    }

    return stateConfig
  }

  const getStateEligibleConfig = (nutrition: boolean) => {
    const stateConfig: StateConfig = {}

    for (const state of allStates) {
      stateConfig[state.code] = {
        fill:
          (nutrition && state.eligibleNutrition) ||
          (!nutrition && state.eligible)
            ? '#5988FF'
            : '#D3D3D3',
      }
    }

    return stateConfig
  }

  const handleDownloadConditions = () => {
    const parser = new Parser()

    const csvData = transpose(
      parser.parse(conditions as Record<string, unknown>),
      ','
    )
    handleDownloadCSV(`Conditions,Count\n${csvData}`, 'Onboarding_Conditions')
  }

  return (
    <div className="analytics-container">
      <Grid container spacing={3}>
        {permissions.includes(Permissions.DOWNLOAD_PATIENTS) && (
          <Grid item xs={12}>
            <div className="d-flex justify-content-end">
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={openExportDataDialog}
              >
                Export Data
              </Button>
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className="d-flex align-items-center justify-content-between">
            <Typography variant="h6">Filters: </Typography>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ marginRight: '15px' }}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-start"
                  label="Starting Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{ 'aria-label': 'change-date' }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-end"
                  label="Ending Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{ 'aria-label': 'change-date' }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </Grid>
        <>
          <Grid item xs={2}>
            <Card className="analytics-card analytics-card-number">
              <AnalyticsNumberCard
                title="Patients"
                number={user.members.total}
              />
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card className="analytics-card analytics-card-number">
              <AnalyticsNumberCard title="Waitlist" number={waitlist.total} />
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card className="analytics-card analytics-card-number">
              <AnalyticsNumberCard
                title="User (Has Account)"
                number={user.total}
              />
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card className="analytics-card analytics-card-number">
              <AnalyticsNumberCard title="User (Active)" number={user.active} />
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card className="analytics-card analytics-card-number">
              <AnalyticsNumberCard
                title="User (Cancelled)"
                number={user.cancelled}
              />
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card className="analytics-card analytics-card-number">
              <AnalyticsNumberCard
                title="User (InActive Dx)"
                number={user.inactiveDx}
              />
            </Card>
          </Grid>
        </>

        <Grid item lg={6} xl={6}>
          <AnalyticsCarouselCard
            components={[
              <AnalyticsNumberPieCard
                key="waitlist-1"
                title="Waitlist Eligibility"
                description="Analysis of eligibility of all waitlist users"
                tableData={[
                  {
                    label: 'Eligible',
                    value: waitlist.eligible,
                    description: `${percentage(
                      waitlist.eligible,
                      waitlist.total
                    )} of total waitlist`,
                  },
                  {
                    label: 'Ineligible',
                    value: waitlist.ineligible,
                    description: `${percentage(
                      waitlist.ineligible,
                      waitlist.total
                    )} of total waitlist`,
                  },
                ]}
                enabled={switches['waitlist']}
                toggle={() => toggleSwitch('waitlist')}
              />,
            ]}
          />
        </Grid>
        <Grid item lg={6} xl={6}>
          <AnalyticsCarouselCard
            components={[
              <AnalyticsNumberPieCard
                key="direct-signup-1"
                title="Direct Signup: All Waitlist"
                description="Breakdown of how all users joined the waitlist"
                tableData={[
                  {
                    label: 'Direct',
                    value: waitlist.direct.total,
                    description: `${percentage(
                      waitlist.direct.total,
                      waitlist.total
                    )} of total waitlist`,
                  },
                  {
                    label: 'Indirect',
                    value: waitlist.indirect.total,
                    description: `${percentage(
                      waitlist.indirect.total,
                      waitlist.total
                    )} of total waitlist`,
                  },
                ]}
                enabled={switches['direct']}
                toggle={() => toggleSwitch('direct')}
              />,
              <AnalyticsNumberPieCard
                key="direct-signup-2"
                title="Direct Signup: Eligible Waitlist"
                description="Breakdown of how eligible users joined the waitlist"
                tableData={[
                  {
                    label: 'Direct',
                    value: waitlist.direct.eligible,
                    description: `${percentage(
                      waitlist.direct.eligible,
                      waitlist.direct.total
                    )} of direct signup are eligible`,
                  },
                  {
                    label: 'Indirect',
                    value: waitlist.indirect.ineligible,
                    description: `${percentage(
                      waitlist.indirect.eligible,
                      waitlist.indirect.total
                    )} of indirect signup are eligible`,
                  },
                ]}
                enabled={switches['direct']}
                toggle={() => toggleSwitch('direct')}
              />,
              <AnalyticsNumberPieCard
                key="direct-signup-2"
                title="Direct Signup: Users"
                description="Breakdown of how users with an account joined the waitlist"
                tableData={[
                  {
                    label: 'Direct',
                    value: user.direct,
                    description: `${percentage(
                      user.direct,
                      user.total
                    )} of users are direct signup`,
                  },
                  {
                    label: 'Indirect',
                    value: user.indirect,
                    description: `${percentage(
                      user.indirect,
                      user.total
                    )} of users are indirect signup`,
                  },
                ]}
                enabled={switches['direct']}
                toggle={() => toggleSwitch('direct')}
              />,
            ]}
          />
        </Grid>

        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="reason-breakdown"
              title="Reason Breakdown"
              description="Formsort 'What brings you to Allara' question"
              tableData={Object.keys(reason).map((label) => ({
                label,
                value: reason[label],
              }))}
              enabled={switches['reason']}
              toggle={() => toggleSwitch('reason')}
            />
          </Card>
        </Grid>
        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="primary-goal-breakdown"
              title="Primary Goal Breakdown"
              description="Formsort biggest concern question"
              tableData={Object.keys(primaryGoal).map((label) => ({
                label,
                value: primaryGoal[label],
              }))}
              enabled={switches['primaryGoal']}
              toggle={() => toggleSwitch('primaryGoal')}
            />
          </Card>
        </Grid>
        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="acne-breakdown"
              title="Acne Breakdown"
              tableData={Object.keys(acne).map((label) => ({
                label,
                value: acne[label],
              }))}
              enabled={switches['acne']}
              toggle={() => toggleSwitch('acne')}
            />
          </Card>
        </Grid>
        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="has-anxiety-breakdown"
              title="Has Anxiety Breakdown"
              tableData={Object.keys(hasAnxiety).map((label) => ({
                label,
                value: hasAnxiety[label],
              }))}
              enabled={switches['hasAnxiety']}
              toggle={() => toggleSwitch('hasAnxiety')}
            />
          </Card>
        </Grid>
        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="birth-control-breakdown"
              title="Birth Control Breakdown"
              tableData={Object.keys(birthControl).map((label) => ({
                label,
                value: birthControl[label],
              }))}
              enabled={switches['birthControl']}
              toggle={() => toggleSwitch('birthControl')}
            />
          </Card>
        </Grid>
        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="hair-growth-breakdown"
              title="Hair Growth Breakdown"
              tableData={Object.keys(hairGrowth).map((label) => ({
                label,
                value: hairGrowth[label],
              }))}
              enabled={switches['hairGrowth']}
              toggle={() => toggleSwitch('hairGrowth')}
            />
          </Card>
        </Grid>
        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="hormones-tested-breakdown"
              title="Hormones Tested Breakdown"
              tableData={Object.keys(hormonesTested).map((label) => ({
                label,
                value: hormonesTested[label],
              }))}
              enabled={switches['hormonesTested']}
              toggle={() => toggleSwitch('hormonesTested')}
            />
          </Card>
        </Grid>
        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="period-regularity-breakdown"
              title="Period Regularity Breakdown"
              tableData={Object.keys(periodRegularity).map((label) => ({
                label,
                value: periodRegularity[label],
              }))}
              enabled={switches['periodRegularity']}
              toggle={() => toggleSwitch('periodRegularity')}
            />
          </Card>
        </Grid>
        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="ttc-breakdown"
              title="Trying to Conceive Breakdown"
              tableData={Object.keys(ttc).map((label) => ({
                label,
                value: ttc[label],
              }))}
              enabled={switches['ttc']}
              toggle={() => toggleSwitch('ttc')}
            />
          </Card>
        </Grid>
        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="weight-breakdown"
              title="Weight Breakdown"
              tableData={Object.keys(weight).map((label) => ({
                label,
                value: weight[label],
              }))}
              enabled={switches['weight']}
              toggle={() => toggleSwitch('weight')}
            />
          </Card>
        </Grid>
        <Grid item md={4} lg={3}>
          <AnalyticsCarouselCard
            components={[
              <AnalyticsNumberPieCard
                key="insurance-provider-breakdown"
                title="Insurance Providers"
                tableData={Object.keys(insuranceProviders).map((label) => ({
                  label,
                  value: insuranceProviders[label],
                }))}
                enabled={switches['hasInsurance']}
                toggle={() => toggleSwitch('hasInsurance')}
              />,
              <AnalyticsNumberPieCard
                key="insurance-breakdown"
                title="Has Insurance Breakdown"
                tableData={Object.keys(hasInsurance).map((label) => ({
                  label,
                  value: hasInsurance[label],
                }))}
                enabled={switches['hasInsurance']}
                toggle={() => toggleSwitch('hasInsurance')}
              />,
            ]}
          />
        </Grid>
        <Grid item md={4} lg={3}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="diagnosed-breakdown"
              title="Diagnosed Breakdown"
              description="Formsort previously diagnosed - conditionally shown based on reason"
              tableData={Object.keys(diagnosed).map((label) => ({
                label,
                value: diagnosed[label],
              }))}
              enabled={switches['diagnosed']}
              toggle={() => toggleSwitch('diagnosed')}
            />
          </Card>
        </Grid>
        <Grid item md={5} lg={4}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberPieCard
              key="how-informed-breakdown"
              title="How Informed Breakdown"
              description="Formsort how informed do you feel question"
              tableData={Object.keys(howInformed).map((label) => ({
                label,
                value: howInformed[label],
              }))}
              enabled={switches['howInformed']}
              toggle={() => toggleSwitch('howInformed')}
            />
          </Card>
        </Grid>
        <Grid item md={7} lg={8}>
          <Card className="analytics-card analytics-card-number">
            <NumberChartCard
              key="treatments-breakdown"
              title="Treatments Breakdown"
              description="Formsort Treatments tried"
              tableData={Object.keys(treatments).map((label) => ({
                label,
                value: treatments[label],
              }))}
              enabled={switches['treatments']}
              toggle={() => toggleSwitch('treatments')}
            />
          </Card>
        </Grid>
        <Grid item md={12} lg={12}>
          <Card className="analytics-card analytics-card-number">
            <NumberChartCard
              key="conditions-breakdown"
              title="Conditions Breakdown"
              description="Formsort Conditions Diagnosed"
              tableData={Object.keys(conditions).map((label) => ({
                label,
                value: conditions[label],
              }))}
              enabled={switches['conditions']}
              toggle={() => toggleSwitch('conditions')}
              onDownload={handleDownloadConditions}
            />
          </Card>
        </Grid>

        <Grid item md={12} lg={6}>
          <Card className="analytics-card analytics-card-number">
            <NumberChartCard
              title="In Control of Health"
              tableData={Object.keys(inControl)
                .map((label) => ({
                  label,
                  value: inControl[label],
                }))
                .sort((a, b) => alphabetize(a.label, b.label))}
              enabled={switches['inControl']}
              toggle={() => toggleSwitch('inControl')}
            />
          </Card>
        </Grid>
        <Grid item md={12} lg={6}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsNumberCard
              title="Eligible Users Account Breakdown"
              number={`${waitlist.eligible} Eligible`}
              tableData={[
                {
                  label: 'Has Account',
                  value: waitlist.eligible - user.noAccount.total,
                  description: `${percentage(
                    waitlist.eligible - user.noAccount.total,
                    waitlist.eligible
                  )} of eligible users`,
                },
                {
                  label: 'No Account',
                  value: user.noAccount.total,
                  description: `${percentage(
                    user.noAccount.total,
                    waitlist.eligible
                  )} of eligible users`,
                },
                {
                  label: 'Direct Signup Has Account',
                  value: waitlist.eligible - user.noAccount.direct,
                  description: `${percentage(
                    waitlist.eligible - user.noAccount.direct,
                    waitlist.eligible
                  )} of eligible direct signup users`,
                },
              ]}
            />
          </Card>
        </Grid>

        <Grid item md={12}>
          <AnalyticsCarouselCard
            components={[
              <div key="eligible-states" className="position-relative">
                <AnalyticsMapCard
                  title="Eligible States"
                  description="Illustration of which states Allara is live in"
                  config={getStateEligibleConfig(false)}
                />
                {permissions.includes(Permissions.UPDATE_ELIGIBLE_STATES) && (
                  <Button
                    onClick={openAddStateDialog}
                    className="position-absolute top-3 right-3"
                    startIcon={<AddLocation />}
                    variant="contained"
                  >
                    Add Eligible State
                  </Button>
                )}
              </div>,
              <div
                key="eligible-nutrition-states"
                className="position-relative"
              >
                <AnalyticsMapCard
                  title="Eligible States: Nutrition"
                  description="Illustration of which states Allara Nutrition Program is live in"
                  config={getStateEligibleConfig(true)}
                />
                {permissions.includes(Permissions.UPDATE_ELIGIBLE_STATES) && (
                  <Button
                    onClick={openAddNutritionStateDialog}
                    className="position-absolute top-3 right-3"
                    startIcon={<AddLocation />}
                    variant="contained"
                  >
                    Add Eligible Nutrition State
                  </Button>
                )}
              </div>,
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <Card className="analytics-card">
            <AnalyticsChartCard
              key="onboarding-funnel"
              title="Onboarding Funnel"
              type="funnel"
              chartData={{
                label: 'Onboarding',
                data: [
                  {
                    label: 'Collect Email',
                    value: waitlist.total,
                  },
                  {
                    label: 'Reason',
                    value: computeTotal(reason),
                  },
                  {
                    label: 'Eligible',
                    value: waitlist.eligible,
                  },
                  {
                    label: 'Biggest Concern',
                    value: computeTotal(primaryGoal),
                  },
                  {
                    label: 'Symptoms',
                    value: computeTotal(hasAnxiety),
                  },
                  {
                    label: 'Complete Formsort',
                    value: computeTotal(hormonesTested),
                  },
                  {
                    label: 'Create Account',
                    value: user.total,
                  },
                  {
                    label: 'View Health Insurance',
                    value: viewHaveInsurance,
                  },
                  {
                    label: 'View Programs',
                    value: viewPrograms,
                  },
                  {
                    label: 'Selected Program',
                    value: computeTotal(waitlist.selectedProgram),
                  },
                  {
                    label: 'View Payment Plans',
                    value: viewPaymentPlans,
                  },
                  {
                    label: 'View Payment',
                    value: viewPayment,
                  },
                  {
                    label: 'Members (complete payment)',
                    value: user.members.total,
                  },
                ],
              }}
            />
          </Card>
        </Grid>

        <Grid item xs={6} lg={4}>
          <AnalyticsCarouselCard
            components={[
              <AnalyticsNumberPieCard
                key="how-did-you-hear"
                title="How Did You Hear"
                tableData={Object.keys(howDidYouHear).map((label) => ({
                  label,
                  value: howDidYouHear[label],
                }))}
                enabled={switches['howDidYouHear']}
                toggle={() => toggleSwitch('howDidYouHear')}
              />,
            ]}
          />
        </Grid>
        <Grid item xs={6} lg={8}>
          <Card className="analytics-card analytics-card-chart">
            <AnalyticsChartCard
              title="Age Distribution of All Waitlist Users"
              type="bar"
              chartData={{
                label: 'Age',
                data: Object.keys(age)
                  .map((a) => ({
                    label: a,
                    value: age[a],
                  }))
                  .filter((a) => a.label !== 'unknown'),
              }}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className="analytics-card analytics-card-number">
            <AnalyticsMapCard
              title="State Heat Map"
              description="Popularity map of waitlist users"
              config={getStateHeatmapConfig()}
            />
          </Card>
        </Grid>

        {/* THIS SECTION IS FOR RAW DATA DISPLAYED ELSEWHERE */}

        <Grid container spacing={3}>
          <Grid item md={12} xl={6}>
            <Card className="analytics-card analytics-card-number">
              <AnalyticsNumberCard
                title="Age"
                description="Age of everyone we have data for"
                tableData={Object.keys(age).map((a) => ({
                  label: a,
                  value: age[a],
                }))}
              />
            </Card>
          </Grid>
          <Grid item md={12} xl={6}>
            <Card className="analytics-card analytics-card-number">
              <AnalyticsNumberCard
                title="State"
                description="State of everyone we have data for"
                tableData={Object.keys(states).map((state) => ({
                  label: state,
                  value: states[state],
                }))}
              />
            </Card>
          </Grid>
          <Grid item md={12} xl={6}>
            <Card className="analytics-card analytics-card-number">
              <AnalyticsNumberCard
                title="Complete Insurance Provider Breakdown"
                description="Includes state and provider as well as indiciation of which pairs are already eligible (sorted by Value desc)"
                tableData={waitlist.insurance
                  .sort((a, b) => b.count - a.count)
                  .map((insurance) => ({
                    label: `${insurance.insuranceProvider} (${insurance.insuranceState})`,
                    value: insurance.count,
                    description: Object.values(insurances).find(
                      (i) =>
                        i.provider === insurance.insuranceProvider &&
                        i.states.find(
                          (s) => s.code === insurance.insuranceState
                        )
                    )
                      ? ''
                      : 'Currently Ineligible',
                  }))}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <AddNutritionStateDialog
        open={openAddNutritionState}
        handleClose={closeAddNutritionStateDialog}
      />
      <ExportDataDialog
        open={openExportData}
        handleClose={closeExportDataDialog}
      />
      <AddStateDialog open={openAddState} handleClose={closeAddStateDialog} />
    </div>
  )
}

export default memo(Analytics)

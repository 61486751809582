import { createContext, useContext, useMemo, useState } from 'react'

interface SearchContextInterface {
  searchText: string
  matchSearch: (keywords: (string | undefined)[]) => boolean
  setSearchText: (value: string) => void
  resetSearchText: () => void
}

export const SearchContext = createContext<SearchContextInterface>(
  {} as SearchContextInterface
)

interface SearchProviderProps {
  children: React.ReactNode
}

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [searchText, setSearchText] = useState<string>('')
  const sanitizedSearchText = useMemo(
    () => searchText.toLowerCase().replace(/ /g, ''),
    [searchText]
  )

  const resetSearchText = () => setSearchText('')

  const matchSearch = (keywords: (string | undefined)[]): boolean => {
    return keywords
      .map((keyword) => keyword?.toLowerCase().replace(/ /g, ''))
      .some((keyword) =>
        keyword ? keyword.includes(sanitizedSearchText) : false
      )
  }

  return (
    <SearchContext.Provider
      value={{ searchText, matchSearch, setSearchText, resetSearchText }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export const useSearchContext = (): SearchContextInterface =>
  useContext(SearchContext)

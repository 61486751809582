import { IconButton } from '@mui/material'
import { PieChart, TableChart } from '@material-ui/icons'
import { memo } from 'react'
import AnalyticsChartCard from './ChartCard'
import AnalyticsNumberCard, { AnalyticsNumberCardProps } from './NumberCard'

const AnalyticsNumberPieCard: React.FC<
  AnalyticsNumberCardProps & { enabled: boolean; toggle: () => void }
> = ({ title, description, number, tableData, enabled, toggle }) => {
  const chartData = {
    label: title,
    data:
      tableData?.map((d) => ({
        label: d.label,
        value: typeof d.value === 'string' ? parseInt(d.value, 10) : d.value,
      })) ?? [],
  }

  return (
    <div className="position-relative">
      {enabled && (
        <AnalyticsNumberCard
          title={title}
          description={description}
          number={number}
          tableData={tableData}
        />
      )}
      {!enabled && (
        <AnalyticsChartCard title={title} type="pie" chartData={chartData} />
      )}
      <IconButton
        aria-label="switch"
        onClick={toggle}
        className="position-absolute top-0 right-0"
      >
        {enabled && <PieChart />}
        {!enabled && <TableChart />}
      </IconButton>
    </div>
  )
}

export default memo(AnalyticsNumberPieCard)

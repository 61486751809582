export const STATES = [
  { code: 'AL', fullName: 'Alabama' },
  { code: 'AK', fullName: 'Alaska' },
  { code: 'AZ', fullName: 'Arizona' },
  { code: 'AR', fullName: 'Arkansas' },
  { code: 'CA', fullName: 'California' },
  { code: 'CO', fullName: 'Colorado' },
  { code: 'CT', fullName: 'Connecticut' },
  { code: 'DE', fullName: 'Delaware' },
  { code: 'DC', fullName: 'District of Columbia' },
  { code: 'FL', fullName: 'Florida' },
  { code: 'GA', fullName: 'Georgia' },
  { code: 'HI', fullName: 'Hawaii' },
  { code: 'ID', fullName: 'Idaho' },
  { code: 'IL', fullName: 'Illinois' },
  { code: 'IN', fullName: 'Indiana' },
  { code: 'IA', fullName: 'Iowa' },
  { code: 'KS', fullName: 'Kansas' },
  { code: 'KY', fullName: 'Kentucky' },
  { code: 'LA', fullName: 'Louisiana' },
  { code: 'ME', fullName: 'Maine' },
  { code: 'MD', fullName: 'Maryland' },
  { code: 'MA', fullName: 'Massachusetts' },
  { code: 'MI', fullName: 'Michigan' },
  { code: 'MN', fullName: 'Minnesota' },
  { code: 'MS', fullName: 'Mississippi' },
  { code: 'MO', fullName: 'Missouri' },
  { code: 'MT', fullName: 'Montana' },
  { code: 'NE', fullName: 'Nebraska' },
  { code: 'NV', fullName: 'Nevada' },
  { code: 'NH', fullName: 'New Hampshire' },
  { code: 'NJ', fullName: 'New Jersey' },
  { code: 'NM', fullName: 'New Mexico' },
  { code: 'NY', fullName: 'New York' },
  { code: 'NC', fullName: 'North Carolina' },
  { code: 'ND', fullName: 'North Dakota' },
  { code: 'OH', fullName: 'Ohio' },
  { code: 'OK', fullName: 'Oklahoma' },
  { code: 'OR', fullName: 'Oregon' },
  { code: 'PA', fullName: 'Pennsylvania' },
  { code: 'RI', fullName: 'Rhode' },
  { code: 'SC', fullName: 'South Carolina' },
  { code: 'SD', fullName: 'South Dakota' },
  { code: 'TN', fullName: 'Tennessee' },
  { code: 'TX', fullName: 'Texas' },
  { code: 'UT', fullName: 'Utah' },
  { code: 'VT', fullName: 'Vermont' },
  { code: 'VA', fullName: 'Virginia' },
  { code: 'WA', fullName: 'Washington' },
  { code: 'WV', fullName: 'West Virginia' },
  { code: 'WI', fullName: 'Wisconsin' },
  { code: 'WY', fullName: 'Wyoming' },
]

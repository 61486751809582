import React from 'react'
import NavBar from 'containers/Dashboard/NavBar'
import { SearchProvider } from 'context/SearchContext/SearchProvider'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import Sidebar from './Sidebar'
import Content from './Content'
import Loader from 'components/Loader'

import './styles.scss'

const DashboardLayout: React.FC = () => {
  const { permissions } = useAuthContext()

  if (!permissions.length) {
    return <Loader />
  }

  return (
    <SearchProvider>
      <div className="dashboard-container">
        <NavBar />
        <Sidebar />
        <Content />
      </div>
    </SearchProvider>
  )
}

export default DashboardLayout

import React, { ReactNode } from 'react'

interface IErrorBoundaryState {
  hasError: boolean
}

export default class ErrorBoundary extends React.Component<
  { children: ReactNode },
  IErrorBoundaryState
> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): IErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error): void {
    // eslint-disable-next-line no-console
    console.error(error.message)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className="d-flex align-items-center bg-light text-primary justify-content-center vh-100">
          <h4 className="mt-4 text-center">
            Something went wrong. Please make sure you are using a supported
            browser (Chrome or Firefox).
          </h4>
        </div>
      )
    }

    return this.props.children
  }
}

import { memo } from 'react'
import USAMap from 'react-usa-map'
import { CardContent, Typography } from '@mui/material'

interface AnalyticsMapCardProps {
  title: string
  description?: string
  config: StateConfig
  interactive?: boolean
}

export type StateConfig = {
  [key: string]: { fill?: string; clickHandler?: () => void }
}

const AnalyticsMapCard: React.FC<AnalyticsMapCardProps> = ({
  title,
  description,
  config,
  interactive = false,
}) => {
  return (
    <CardContent>
      <Typography variant="h6" className="analytics-card-title">
        {title}
      </Typography>
      {description && (
        <Typography variant="subtitle2">{description}</Typography>
      )}
      <div className={interactive ? 'interactive-map' : ''}>
        <USAMap height customize={config} />
      </div>
    </CardContent>
  )
}

export default memo(AnalyticsMapCard)

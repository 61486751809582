import { Patient, PatientRowData } from 'containers/Dashboard/types'
import { patientToRowDataProperty } from './patient'

export type Order = 'asc' | 'desc'

export function alphabetize(a: string, b: string): -1 | 0 | 1 {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0
  }
}

export function sortPatients(
  patients: Patient[],
  order: Order,
  orderBy: keyof PatientRowData
): Patient[] {
  return patients.sort((a: Patient, b: Patient) => {
    if (order === 'asc') {
      return comparePatients(a, b, orderBy)
    } else {
      return -1 * comparePatients(a, b, orderBy)
    }
  })
}

/**
 * Ascending comparator for patients based on key
 *
 * @param a - first patient
 * @param b - second patient
 * @param key - the property to sort by
 * @returns order of two patients
 */
function comparePatients(
  patA: Patient,
  patB: Patient,
  key: keyof PatientRowData
): -1 | 0 | 1 {
  const a = patientToRowDataProperty(patA, key)
  const b = patientToRowDataProperty(patB, key)

  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0
  }
}

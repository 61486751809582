import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Loader from 'components/Loader'
import { RoutePaths } from 'containers/Core/Routes'

const Logout: React.FC = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    logout({ returnTo: window.location.origin + RoutePaths.LOGIN })
  }, [])

  return <Loader />
}

export default Logout

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { Close as CloseIcon, GetApp as DownloadIcon } from '@material-ui/icons'
import useDownloadCSV from 'hooks/useDownloadCSV'
import { memo, useState } from 'react'
import { handleDownloadCSV } from 'utils/csv'
import { Permissions } from 'containers/Core/utils'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import ExportTimeFrameDataDialog from './ExportTimeFrameDataDialog'

interface ExportDataDialogProps {
  open: boolean
  handleClose: () => void
}

const ExportDataDialog: React.FC<ExportDataDialogProps> = ({
  open,
  handleClose,
}) => {
  const {
    downloadConversionCSV,
    downloadAppointmentsCSV,
    downloadMessagingSLACSV,
    downloadActivePatientsCSV,
    downloadSourceAnalyticsCSV,
    downloadAppointmentSurveyCSV,
    downloadMonthlyCheckinsCSV,
    downloadCancelledAppointmentsCSV,
  } = useDownloadCSV()
  const { permissions } = useAuthContext()
  const [openAppointments, setOpenAppointments] = useState<boolean>(false)
  const [openMessagingSLA, setOpenMessagingSLA] = useState<boolean>(false)
  const [openActivePatients, setOpenActivePatients] = useState<boolean>(false)
  const [openSourceAnalytics, setOpenSourceAnalytics] = useState<boolean>(false)
  const [openCancelledAppointments, setOpenCancelledAppointments] =
    useState<boolean>(false)

  const closeAppointmentsDialog = () => setOpenAppointments(false)

  const openAppointmentsDialog = () => {
    setOpenAppointments(true)
    handleClose()
  }

  const closeSourceAnalyticsDialog = () => setOpenSourceAnalytics(false)

  const openSourceAnalyticsDialog = () => {
    setOpenSourceAnalytics(true)
    handleClose()
  }

  const closeActivePatientsDialog = () => setOpenActivePatients(false)

  const openActivePatientsDialog = () => {
    setOpenActivePatients(true)
    handleClose()
  }

  const closeCancelledAppointmentsDialog = () =>
    setOpenCancelledAppointments(false)

  const openCancelledAppointmentsDialog = () => {
    setOpenCancelledAppointments(true)
    handleClose()
  }

  const closeMessagingSLADialog = () => {
    setOpenMessagingSLA(false)
  }

  const openMessagingSLADialog = () => {
    setOpenMessagingSLA(true)
    handleClose()
  }

  const handleDownloadConversionCSV = async () => {
    const csvString = await downloadConversionCSV()
    handleDownloadCSV(csvString, 'allara_conversion_metrics')
  }

  const handleDownloadAppointmentSurveyCSV = async () => {
    const csvString = await downloadAppointmentSurveyCSV()
    handleDownloadCSV(csvString, 'allara_post_appointment_survey')
  }

  const handleDownloadMonthlyCheckinsCSV = async () => {
    const csvString = await downloadMonthlyCheckinsCSV()
    handleDownloadCSV(csvString, 'allara_monthly_checkins_survey')
  }

  if (!permissions.includes(Permissions.DOWNLOAD_PATIENTS)) {
    return <></>
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="add-module-dialog-title"
        fullWidth
        maxWidth="md"
        className="add-module-dialog"
      >
        <DialogTitle id="add-module-dialog-title">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-button"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="mb-5">
          <Typography variant="h4" className="title">
            Select which kind of data you would like to download:
          </Typography>
          <div className="d-flex align-items-center w-100 mt-2">
            <div className="w-50">
              <Button
                className="w-100"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadMonthlyCheckinsCSV}
              >
                Download Monthly Checkins CSV
              </Button>
            </div>
            <div style={{ width: '20px' }} />
            <div className="w-50">
              <Button
                className="w-100"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadAppointmentSurveyCSV}
              >
                Download Appointment Survey CSV
              </Button>
            </div>
          </div>
          <div className="d-flex w-100 mt-3">
            <div className="w-50">
              <Button
                className="w-100"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadConversionCSV}
              >
                Download Conversion CSV
              </Button>
            </div>
            <div style={{ width: '20px' }} />
            <div className="w-50">
              <Button
                className="w-100"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={openActivePatientsDialog}
              >
                Download Active Patients CSV
              </Button>
            </div>
          </div>
          <div className="d-flex w-100 mt-3">
            <div className="w-50">
              <Button
                className="w-100"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={openAppointmentsDialog}
              >
                Download Appointments CSV
              </Button>
            </div>
            <div style={{ width: '20px' }} />
            <div className="w-50">
              <Button
                className="w-100"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={openSourceAnalyticsDialog}
              >
                Download Source Analytics CSV
              </Button>
            </div>
          </div>
          <div className="d-flex w-100 mt-3">
            <div className="w-50">
              <Button
                className="w-100"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={openMessagingSLADialog}
              >
                Download Messaging SLA CSV
              </Button>
            </div>
            <div style={{ width: '20px' }} />
            <div className="w-50">
              <Button
                className="w-100"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={openCancelledAppointmentsDialog}
              >
                Download Cancelled Appointments CSV
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ExportTimeFrameDataDialog
        open={openAppointments}
        filename="allara_appointments"
        permissions={[Permissions.DOWNLOAD_PATIENTS]}
        name={'appointments'}
        handleClose={closeAppointmentsDialog}
        toggle={{
          label: 'Inlude Bill ID',
          values: {
            enabled: 'bill-id',
            disabled: false,
          },
        }}
        downloadCSV={downloadAppointmentsCSV}
      />
      <ExportTimeFrameDataDialog
        open={openSourceAnalytics}
        filename="source_analytics"
        permissions={[Permissions.DOWNLOAD_PATIENTS]}
        name="source productivity data"
        subtitle="Please note: this pull may take up to a minute"
        handleClose={closeSourceAnalyticsDialog}
        downloadCSV={downloadSourceAnalyticsCSV}
      />
      <ExportTimeFrameDataDialog
        open={openActivePatients}
        filename="active_patients"
        permissions={[Permissions.DOWNLOAD_PATIENTS]}
        name="active patients"
        subtitle="Please note: this usually takes about a minute for a month of data. If you need more than 2 months of data, please reach out to Product/Eng team."
        handleClose={closeActivePatientsDialog}
        downloadCSV={downloadActivePatientsCSV}
      />
      <ExportTimeFrameDataDialog
        open={openCancelledAppointments}
        filename="cancelled_appointments"
        permissions={[Permissions.DOWNLOAD_PATIENTS]}
        name="cancelled appointments"
        subtitle="Please note: the start and end time reflect the time the appointment was cancelled and NOT the time of the appointment."
        handleClose={closeCancelledAppointmentsDialog}
        downloadCSV={downloadCancelledAppointmentsCSV}
      />
      <ExportTimeFrameDataDialog
        open={openMessagingSLA}
        filename="messaging_sla"
        permissions={[Permissions.DOWNLOAD_PATIENTS]}
        name="messaging SLA"
        subtitle="Please note: the start and end time reflect the time the message was sent by the patient."
        handleClose={closeMessagingSLADialog}
        downloadCSV={downloadMessagingSLACSV}
      />
    </>
  )
}

export default memo(ExportDataDialog)

import { RoutePaths } from 'containers/Core/Routes'
import { Redirect, Route, Switch } from 'react-router'
import Analytics from './Analytics'
import EligibleInsuranceList from './EligibleInsuranceList'
import PatientRouter from './Patients/PatientRouter'
import ProvidersList from './ProvidersList'

const Content: React.FC = () => {
  return (
    <main className="content">
      <div className="spacer" />
      <Switch>
        <Route path={RoutePaths.DASHBOARD_ANALYTICS} component={Analytics} />
        <Route
          path={`${RoutePaths.DASHBOARD_PATIENTS}/:patientId?/:view?`}
          component={PatientRouter}
        />
        <Route
          path={`${RoutePaths.DASHBOARD_PROVIDERS}/:providerId?`}
          component={ProvidersList}
        />
        <Route
          path={`${RoutePaths.DASHBOARD_ELIGIBLE_INSURANCES}/:provider?`}
          component={EligibleInsuranceList}
        />
        <Route path={RoutePaths.DASHBOARD}>
          <Redirect to={RoutePaths.DASHBOARD_PATIENTS} />
        </Route>
      </Switch>
    </main>
  )
}

export default Content

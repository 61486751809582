import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import React, { memo, useRef, useState } from 'react'
import { Close as CloseIcon } from '@material-ui/icons'
import useProviders from 'hooks/useProviders'

interface NewProviderDialogProps {
  open: boolean
  handleClose: () => void
}

const NewProviderDialog: React.FC<NewProviderDialogProps> = ({
  open,
  handleClose,
}) => {
  const { handleAddProvider } = useProviders()
  const firstNameInputRef = useRef<HTMLInputElement>(null)
  const lastNameInputRef = useRef<HTMLInputElement>(null)
  const credentialsInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [typeValue, setTypeValue] = useState<'Medical Provider' | 'Dietician'>(
    'Medical Provider'
  )
  const handleChangeTypeValue = (event: SelectChangeEvent) =>
    setTypeValue(event.target.value as 'Medical Provider' | 'Dietician')

  const computeDisabled = () => {
    const allRequiredFieldsSet =
      firstNameInputRef.current?.value &&
      lastNameInputRef.current?.value &&
      credentialsInputRef.current?.value &&
      emailInputRef.current?.value
    setDisabled(!Boolean(allRequiredFieldsSet))
  }

  const handleSubmit = () => {
    if (
      firstNameInputRef.current?.value &&
      lastNameInputRef.current?.value &&
      credentialsInputRef.current?.value &&
      emailInputRef.current?.value
    ) {
      const provider = {
        firstName: firstNameInputRef.current.value,
        lastName: lastNameInputRef.current.value,
        credentials: credentialsInputRef.current.value,
        email: emailInputRef.current.value,
        type: typeValue,
        isActive: true,
      }
      handleAddProvider(provider)
      handleClose()
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="add-provider-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="add-provider-dialog-title">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" className="dialog-title-text">
          Add New Provider
        </Typography>
        <Typography variant="h6">
          This does not create the physician in Elation or Source. Please create
          the physician in Elation and Source first and then return to this
          form.
        </Typography>
        <form className="new-asset-form">
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <TextField
                fullWidth
                required
                label="First Name"
                className="new-asset-textfield"
                inputRef={firstNameInputRef}
                onChange={computeDisabled}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                fullWidth
                required
                label="Last Name"
                className="new-asset-textfield"
                inputRef={lastNameInputRef}
                onChange={computeDisabled}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                fullWidth
                required
                label="Email"
                className="new-asset-textfield"
                inputRef={emailInputRef}
                onChange={computeDisabled}
              />
            </Grid>
            <Grid item sm={2}>
              <TextField
                fullWidth
                required
                label="Credentials"
                className="new-asset-textfield"
                inputRef={credentialsInputRef}
                onChange={computeDisabled}
              />
            </Grid>
            <Grid item sm={3}>
              <FormControl className="select-form-control">
                <InputLabel id="provider-type-label">Type</InputLabel>
                <Select
                  labelId="provider-type-label"
                  value={typeValue}
                  onChange={handleChangeTypeValue}
                  label="Type"
                >
                  <MenuItem value="Medical Provider">Medical Provider</MenuItem>
                  <MenuItem value="Dietician">Dietician</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className="action-buttons">
            <Button
              variant="contained"
              className="cancel-button"
              size="large"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="submit-button"
              size="large"
              disabled={disabled}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default memo(NewProviderDialog)

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
  Stack,
  Switch,
} from '@mui/material'
import { Close as CloseIcon } from '@material-ui/icons'
import { AppointmentAvailability as AppointmentAvailabilityRoute } from 'api/admin/routes'
import Loader from 'components/Loader'
import StateSelect from 'components/StateSelect'
import useAppointmentTypes from 'hooks/useAppointmentTypes'
import { useState } from 'react'
import { getAxiosConfig } from 'utils/api'

import './styles.scss'

interface AvailabilityDialogProps {
  open: boolean
  handleClose: () => void
}

interface AvailabilityData {
  available: number
  slots: string[]
}

const AvailabilityDialog: React.FC<AvailabilityDialogProps> = ({
  open,
  handleClose,
}) => {
  const { appointmentTypes } = useAppointmentTypes()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [availability, setAvailability] = useState<AvailabilityData>()
  const [selectedState, setSelectedState] = useState<string>('')
  const [credentialedOnly, setCredentialedOnly] = useState<boolean>(false)
  const [selectedAppointmentType, setSelecetedAppointmentType] =
    useState<string>('')

  const handleUpdateAppointmentType = (event: SelectChangeEvent) =>
    setSelecetedAppointmentType(event.target.value as string)

  const handleUpdateSelectedState = (event: SelectChangeEvent) =>
    setSelectedState(event.target.value as string)

  const handleUpdateCredentialedOnly = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCredentialedOnly(event.target.checked)
  }

  const handleSubmit = async () => {
    if (!isAuthenticated) {
      return
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${AppointmentAvailabilityRoute(
        selectedAppointmentType,
        selectedState,
        credentialedOnly ? true : false
      )}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json()
          setAvailability(data)
        }
      })
      .catch(() => {
        return
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="availability-dialog-title"
      fullWidth
      maxWidth="md"
      className="availability-dialog"
    >
      <DialogTitle id="availability-dialog-title">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" className="title">
          Check Availability for Appointment Types
        </Typography>
        <Typography variant="h6">
          Select the parameters below and then run. This will find the number of
          appointments in the next 30 days and when the next 5 available slots
          are.
        </Typography>
        <form className="mt-2">
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <FormControl required className="select-form-control">
                <InputLabel id="appointment-type-label">
                  Appointment Type
                </InputLabel>
                <Select
                  labelId="appointment-type-label"
                  value={selectedAppointmentType}
                  onChange={handleUpdateAppointmentType}
                  label="Appointment Type"
                >
                  {appointmentTypes.map((appointmentType) => (
                    <MenuItem
                      key={appointmentType.id}
                      value={appointmentType.id}
                    >
                      {appointmentType.category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <FormControl required className="select-form-control">
                <InputLabel id="state-label">State</InputLabel>
                <StateSelect
                  label="State"
                  labelId="state-select"
                  value={selectedState}
                  onChange={handleUpdateSelectedState}
                />
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <FormControl required className="select-form-control">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Only show Credentialed?</Typography>
                  <Switch
                    defaultChecked
                    onChange={handleUpdateCredentialedOnly}
                  />
                </Stack>
              </FormControl>
            </Grid>
          </Grid>
          <div className="d-flex justify-content-end mt-4">
            <Button
              variant="contained"
              className="submit-button"
              size="large"
              disabled={!selectedState || !selectedAppointmentType}
              onClick={handleSubmit}
            >
              Get Availability
            </Button>
          </div>
        </form>
        {isLoading && (
          <div>
            <Loader />
          </div>
        )}
        {!isLoading && availability && (
          <div>
            <p>
              <strong>Available Slots:</strong>{' '}
              {availability.available ?? 'Error'}
            </p>
            <p>
              <strong className="mb-1">
                Next {availability.slots?.length ?? ''} Available Slots:
              </strong>
              {availability.slots?.map((slot, i) => (
                <p key={i} className="mb-0">
                  {new Date(slot).toLocaleString()}
                </p>
              ))}
            </p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AvailabilityDialog

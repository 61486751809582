import { useAuth0 } from '@auth0/auth0-react'
import { EligibleInsurances as EligibleInsurancesRoute } from 'api/admin/routes'
import axios, { AxiosResponse } from 'axios'
import { EligibleInsurance, State } from 'containers/Dashboard/types'
import { useAlertContext } from 'context/AlertContext'
import produce from 'immer'
import { useMemo } from 'react'
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { getAxiosConfig, QUERY_KEYS } from 'utils/api'

export interface Insurance {
  states: State[]
  title: string
  provider: string
}

export interface Insurances {
  [key: string]: Insurance
}

interface UseEligibleInsuranceInterface {
  isLoading: boolean
  error: unknown
  insurances: Insurances
  handleAddState: UseMutateFunction<
    AxiosResponse<EligibleInsurance> | null,
    unknown,
    EligibleInsurance,
    unknown
  >
  handleAddEligibleInsurance: UseMutateFunction<
    AxiosResponse<EligibleInsurance> | null,
    unknown,
    EligibleInsurance,
    unknown
  >
}

const useEligibleInsurances = (): UseEligibleInsuranceInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const fetchEligibleInsurances = async (): Promise<EligibleInsurance[]> => {
    if (!isAuthenticated) {
      return []
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${EligibleInsurancesRoute}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json()
        } else {
          setAlertText(
            `Error loading eligible insurances (${res.status} ${res.statusText})`
          )
          return []
        }
      })
      .catch(() => {
        setAlertText('Error loading eligible insurances.')
        return []
      })
  }

  const { isLoading, error, data } = useQuery(
    QUERY_KEYS.ELIGIBLE_INSURANCES,
    fetchEligibleInsurances,
    { staleTime: Infinity }
  )

  const addEligibleInsurance = async (
    eligibleInsurance: EligibleInsurance
  ): Promise<AxiosResponse<EligibleInsurance> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${EligibleInsurancesRoute}`,
        eligibleInsurance,
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to add eligible insurance!')
        } else {
          setAlertText(err.message)
        }

        return null
      })
  }

  const mutateAdd = useMutation(addEligibleInsurance, {
    onSettled: () =>
      queryClient.invalidateQueries(QUERY_KEYS.ELIGIBLE_INSURANCES),
    onMutate: async (eligibleInsurance: EligibleInsurance) => {
      await queryClient.cancelQueries(QUERY_KEYS.ELIGIBLE_INSURANCES)

      const previousData = queryClient.getQueryData<EligibleInsurance[]>(
        QUERY_KEYS.ELIGIBLE_INSURANCES
      )

      if (previousData) {
        const nextState = produce(previousData, (draft) => {
          draft.push(eligibleInsurance)
        })
        queryClient.setQueryData<EligibleInsurance[]>(
          QUERY_KEYS.ELIGIBLE_INSURANCES,
          nextState
        )
      }

      return { previousData }
    },
  })

  const handleAddEligibleInsurance = mutateAdd.mutate

  const addState = async (
    eligibleInsurance: EligibleInsurance
  ): Promise<AxiosResponse<EligibleInsurance> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${EligibleInsurancesRoute}/add-state`,
        eligibleInsurance,
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to add state to eligible insurance!')
        } else {
          setAlertText(err.message)
        }

        return null
      })
  }

  const mutateAddState = useMutation(addState, {
    onSettled: () =>
      queryClient.invalidateQueries(QUERY_KEYS.ELIGIBLE_INSURANCES),
    onMutate: async (eligibleInsurance: EligibleInsurance) => {
      await queryClient.cancelQueries(QUERY_KEYS.ELIGIBLE_INSURANCES)

      const previousData = queryClient.getQueryData<EligibleInsurance[]>(
        QUERY_KEYS.ELIGIBLE_INSURANCES
      )

      if (previousData) {
        const nextState = produce(previousData, (draft) => {
          draft.push(eligibleInsurance)
        })
        queryClient.setQueryData<EligibleInsurance[]>(
          QUERY_KEYS.ELIGIBLE_INSURANCES,
          nextState
        )
      }

      return { previousData }
    },
  })

  const handleAddState = mutateAddState.mutate

  const insurances = useMemo(() => {
    if (data) {
      return data.reduce((acc, insurance) => {
        if (acc[insurance.provider]) {
          acc[insurance.provider].states.push(insurance.state)
        } else {
          acc[insurance.provider] = {
            states: [insurance.state],
            title: insurance.title,
            provider: insurance.provider,
          }
        }

        return acc
      }, {} as Insurances)
    } else {
      return {}
    }
  }, [data])

  return {
    isLoading,
    error,
    insurances,
    handleAddState,
    handleAddEligibleInsurance,
  }
}

export default useEligibleInsurances

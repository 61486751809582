export const Patients = '/api/v1/admin/patients'
export const Patient = (id?: string): string => `${Patients}/${id}`
export const Pregnancy = (id?: string): string =>
  `${Patients}/pregnancy-survey-link/${id}`

export const PatientProvider = '/api/v1/admin/patients/provider'

export const Providers = '/api/v1/admin/providers'

export const Analytics = '/api/v1/admin/analytics'
export const AppointmentAvailability = (
  appointmentType: string,
  state: string,
  credentialed: boolean
): string =>
  `${Analytics}/appointment-availability?appointment-type=${appointmentType}&state=US-${state}&credentialed=${credentialed}`

export const EligibleInsurances = '/api/v1/admin/eligible-insurances'

export const WaitlistUser = '/api/v1/admin/waitlist-users'

export const PatientsCSV = `${Patients}/export`
export const ConversionCSV = `${Analytics}/conversion/export`
export const AppointmentsCSV = `${Analytics}/appointments/export`
export const MessagingSLACSV = `${Analytics}/messaging-sla/export`
export const ActivePatientsCSV = `${Analytics}/active-patients/export`
export const AppointmentSurveyCSV = `${Analytics}/appointment-followup/export`
export const MonthlyCheckinsCSV = `${Analytics}/monthly-checkins/export`
export const SourceAnalyticsCSV = `${Analytics}/source-analytics/export`
export const InsuranceAppointmentsCSV = `${Analytics}/insurance-appointments/export`
export const CancelledAppointmentsCSV = `${Analytics}/appointments-cancellation/export`

export const States = '/api/v1/states'

import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Route, RouteProps } from 'react-router'

import Loader from 'components/Loader'

interface ProtectedRouteProps extends RouteProps {
  path: string
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  path,
  ...props
}) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0()

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        redirectUri: window.location.origin + path,
      })
    }
  }, [isAuthenticated, isLoading])

  if (!isAuthenticated) {
    return <Loader />
  }

  return (
    <Route path={path} {...props}>
      {children}
    </Route>
  )
}

export default ProtectedRoute

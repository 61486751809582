import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { Provider } from 'containers/Dashboard/types'
import { Close as CloseIcon } from '@material-ui/icons'
import DataEditRow, { DataType, Option } from 'components/DataEditRow'
import { useCallback } from 'react'
import useProviders from 'hooks/useProviders'
import produce from 'immer'
import { useAlertContext } from 'context/AlertContext'

import './styles.scss'

interface ProviderDialogProps {
  provider: Provider | null
  readOnly?: boolean
  handleClose: () => void
}

const ProviderDialog: React.FC<ProviderDialogProps> = ({
  provider,
  readOnly = false,
  handleClose,
}) => {
  const { setAlertText } = useAlertContext()
  const { handleUpdateProvider } = useProviders()

  const options: DataType[] = [
    {
      label: 'Elation ID',
      value: provider?.id ?? '',
      description: 'Elation Physician ID (cannot be changed)',
      editable: false,
    },
    {
      label: 'First Name',
      value: provider?.firstName ?? '',
      description: 'Provider First Name',
    },
    {
      label: 'Last Name',
      value: provider?.lastName ?? '',
      description: 'Provider Last Name',
    },
    {
      label: 'Email',
      value: provider?.email ?? '',
      description: 'Provider Email',
    },
    {
      label: 'Credentials',
      value: provider?.credentials ?? '',
      description: 'Provider Credentials',
    },
    {
      label: 'Source Health ID',
      value: provider?.sourceHealthId ?? '',
      description: 'Provider Source Health ID',
      editable: false,
    },
    {
      label: 'Type',
      value: provider?.type ?? '',
      description:
        'Indicates whether provider is a dietician or medical provider',
      options: [
        { label: 'Medical Provider', value: 'Medical Provider' },
        { label: 'Dietician', value: 'Dietician' },
      ],
    },
  ]

  const handleUpdate = useCallback(
    (label: string, value: string | boolean | Date) => {
      if (readOnly) {
        setTimeout(() => {
          setAlertText('You do not have permission to edit providers')
        }, 100)
        return
      }

      const newProvider = produce(provider, (draftProvider: Provider) => {
        switch (typeof value) {
          case 'string':
            switch (label) {
              case 'Elation ID':
                draftProvider.id = value
                break
              case 'First Name':
                draftProvider.firstName = value
                break
              case 'Last Name':
                draftProvider.lastName = value
                break
              case 'Email':
                draftProvider.email = value
                break
              case 'Credentials':
                draftProvider.credentials = value
                break
              case 'Type':
                const type =
                  value === 'Medical Provider' || value === 'Dietician'
                    ? value
                    : 'Medical Provider'
                draftProvider.type = type
                break
            }

            break
        }
      })

      if (newProvider) {
        handleUpdateProvider(newProvider)
      }
    },
    [readOnly, provider, setAlertText]
  )

  const handleUpdateList = useCallback(
    (label: string, values: Option[]) => {
      if (readOnly || !values) {
        setTimeout(() => {
          setAlertText('You do not have permission to edit providers')
        }, 100)
        return
      }

      if (!provider) {
        return
      }

      switch (label) {
        default:
          return
      }
    },
    [provider]
  )

  return (
    <Dialog
      onClose={handleClose}
      open={provider !== null}
      aria-labelledby="provider-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="provider-dialog-title">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" className="provider-name">
          {provider?.firstName} {provider?.lastName}
        </Typography>
        <div className="options-container">
          {options.map((option) => (
            <DataEditRow
              key={option.label}
              data={option}
              updateData={handleUpdate}
              updateListData={handleUpdateList}
            />
          ))}
        </div>
        <div className="action-button-container"></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{readOnly ? 'Close' : 'Save'}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProviderDialog

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material'
import { Autocomplete } from '@material-ui/lab'
import { useCallback, useState } from 'react'

import './styles.scss'
import { Link } from '@material-ui/icons'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

type ListData = {
  label: string
  value: Option[]
  description: string
  options?: Option[]
  clearable?: boolean
}

type SelectData = {
  label: string
  value: string | undefined
  description: string
  options: Option[]
}

type StringData = {
  label: string
  value: string | undefined
  description: string
}

export type Option = {
  label: string
  value: string
}

export type DataType = {
  label: string
  value: string | Option[] | boolean | Date | undefined
  description: string
  link?: string
  options?: Option[]
  editable?: boolean
  clearable?: boolean
  listAddable?: boolean
}

interface DataEditRowProps {
  data: DataType
  updateData?: (key: string, value: string | boolean | Date) => void
  updateListData?: (key: string, value: Option[]) => void
}

export const UNASSIGNED_VALUE = ''

const DataEditRow: React.FC<DataEditRowProps> = ({
  data,
  updateData,
  updateListData,
}) => {
  const handleUpdate = useCallback(
    (dataValue: string) => {
      if (updateData) {
        updateData(data.label, dataValue)
      }

      return
    },
    [data]
  )

  const handleBooleanToggle = useCallback(
    (dataValue: boolean) => {
      if (updateData) {
        updateData(data.label, dataValue)
      }
    },
    [data]
  )

  const handleUpdateList = useCallback(
    (dataValue: Option[]) => {
      if (updateListData) {
        updateListData(data.label, dataValue)
      }

      return
    },
    [data]
  )
  const handleDateUpdate = useCallback((date: Date) => {
    if (updateData) {
      updateData(data.label, date)
    }
  }, [])

  let renderRow

  if (data.options && typeof data.value === 'string') {
    renderRow = (
      <SelectDataEditRow
        data={data as SelectData}
        handleUpdate={handleUpdate}
      />
    )
  } else if (data.editable !== undefined && data.editable === false) {
    renderRow = (
      <UneditableDataRow text={data.value as string} link={data.link} />
    )
  } else if (typeof data.value === 'string') {
    renderRow = (
      <StringDataEditRow
        data={data as StringData}
        handleUpdate={handleUpdate}
      />
    )
  } else if (typeof data.value === 'boolean') {
    renderRow = (
      <BooleanDataEditRow
        value={data.value as boolean}
        handleUpdate={handleBooleanToggle}
      />
    )
  } else if (typeof data.value === 'object') {
    if (data.value instanceof Date) {
      renderRow = (
        <DateEditRow
          data={data.value as Date}
          handleDateUpdate={handleDateUpdate}
        />
      )
    } else {
      renderRow = (
        <ListDataEditRow
          data={data as ListData}
          handleUpdateList={handleUpdateList}
        />
      )
    }
  } else {
    renderRow = <UneditableDataRow text="unknown" />
  }

  return (
    <div className="option">
      <div className="option-label">
        <Tooltip arrow title={data.description}>
          <span className="option-text">{data.label}</span>
        </Tooltip>
      </div>

      <div className="row-spacer" />
      {renderRow}
      <div className="row-spacer" />
    </div>
  )
}

interface UneditableDataRowProps {
  text: string
  link?: string
}

const UneditableDataRow: React.FC<UneditableDataRowProps> = ({
  text,
  link,
}) => {
  return (
    <div className="option-uneditable">
      <div>{text}</div>
      {link && (
        <IconButton href={link} target="_blank">
          <Link />
        </IconButton>
      )}
    </div>
  )
}

interface ListDataEditRowProps {
  data: ListData
  handleUpdateList: (value: Option[]) => void
}

const ListDataEditRow: React.FC<ListDataEditRowProps> = ({
  data,
  handleUpdateList,
}) => {
  const handleChange = useCallback(
    (_: React.ChangeEvent<unknown>, value: Option[]) => {
      handleUpdateList(value)
    },
    []
  )

  return (
    <div className="option-list">
      <Autocomplete
        multiple
        autoHighlight
        options={data.options ?? []}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.label === value.label}
        defaultValue={data.value}
        onChange={handleChange}
        filterSelectedOptions
        disabled={data.options === undefined}
        disableClearable={data.clearable === false}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={data.label}
            placeholder="Select option"
          />
        )}
      />
    </div>
  )
}

interface SelectDataEditRowProps {
  data: SelectData
  handleUpdate: (value: string) => void
}

const SelectDataEditRow: React.FC<SelectDataEditRowProps> = ({
  data,
  handleUpdate,
}) => {
  const [value, setValue] = useState<string>(data.value ?? '')

  const handleSelectChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string
    setValue(newValue)
    handleUpdate(newValue)
  }

  return (
    <div className="option-select">
      <FormControl className="select-form-control">
        <InputLabel id={`${data.label}-outlined-label`}>
          {data.label}
        </InputLabel>
        <Select
          labelId={`${data.label}-outlined-label`}
          value={value}
          onChange={handleSelectChange}
          label={data.label}
        >
          {data.options.map((selectOption) => (
            <MenuItem key={selectOption.value} value={selectOption.value}>
              {selectOption.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

interface StringDataEditRowProps {
  data: StringData
  handleUpdate: (value: string) => void
}

const StringDataEditRow: React.FC<StringDataEditRowProps> = ({
  data,
  handleUpdate,
}) => {
  const [value, setValue] = useState<string>(data.value ?? '')
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value)

  const handleUpdateText = useCallback(() => {
    if (value !== data.value) {
      handleUpdate(value)
    }
  }, [value])

  return (
    <div className="option-input">
      <TextField
        fullWidth
        label={data.label}
        value={value}
        onChange={handleTextChange}
        InputProps={{
          classes: { input: 'option-textfield' },
          onBlur: handleUpdateText,
        }}
        InputLabelProps={{ classes: { root: 'option-textfield-label' } }}
      />
    </div>
  )
}

interface BooleanDataEditRowProps {
  value: boolean
  handleUpdate: (value: boolean) => void
}

const BooleanDataEditRow: React.FC<BooleanDataEditRowProps> = ({
  value,
  handleUpdate,
}) => {
  const [toggle, setToggle] = useState<boolean>(value)

  const toggleSwitch = useCallback(() => {
    setToggle(!toggle)
    handleUpdate(!toggle)
  }, [toggle])

  return (
    <div className="option-input">
      <Switch
        checked={toggle}
        onChange={toggleSwitch}
        name="data-edit-row"
        inputProps={{ 'aria-label': 'primary checkbox' }}
        color="primary"
      />
    </div>
  )
}

interface DateEditRowProps {
  data: Date
  handleDateUpdate: (value: Date) => void
}

const DateEditRow: React.FC<DateEditRowProps> = ({
  data,
  handleDateUpdate,
}) => {
  const handleChange = useCallback((date: Date | null) => {
    handleDateUpdate(date as Date)
  }, [])

  return (
    <div className="option-list">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{ marginRight: '15px' }}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-start"
          label="Date of Birth"
          value={data}
          onChange={handleChange}
          KeyboardButtonProps={{ 'aria-label': 'change-date' }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default DataEditRow

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import { Close as CloseIcon } from '@material-ui/icons'
import { Patient } from 'containers/Dashboard/types'
import useTimeline from 'hooks/useTimeline'
import useTimelineModules from 'hooks/useTimelineModules'
import { useRef, useState } from 'react'

import './styles.scss'

interface AddModuleDialogProps {
  show: boolean
  patient: Patient
  handleClose: () => void
}

const AddModuleDialog: React.FC<AddModuleDialogProps> = ({
  show,
  patient,
  handleClose,
}) => {
  const offsetInputRef = useRef<HTMLInputElement>(null)
  const { handleAddModule } = useTimeline(patient.id)
  const { modules, conditions } = useTimelineModules()
  const [moduleValue, setModuleValue] = useState<string>('')
  const [applicabilityConditionValue, setApplicabilityConditionValue] =
    useState<string>('')

  const handleChangeModuleValue = (event: SelectChangeEvent) =>
    setModuleValue(event.target.value as string)

  const handleChangeApplicabilityConditionValue = (event: SelectChangeEvent) =>
    setApplicabilityConditionValue(event.target.value as string)

  const handleSubmit = () => {
    handleAddModule({
      moduleId: moduleValue,
      user: patient,
      applicabilityCondition: applicabilityConditionValue
        ? conditions.find((c) => c.code === applicabilityConditionValue)
        : undefined,
      offsetDelay: offsetInputRef.current
        ? parseInt(offsetInputRef.current.value, 10)
        : undefined,
    })
    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={show}
      aria-labelledby="add-module-dialog-title"
      fullWidth
      maxWidth="md"
      className="add-module-dialog"
    >
      <DialogTitle id="add-module-dialog-title">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" className="title">
          Add Module to Care Timeline for {patient.firstName} {patient.lastName}
        </Typography>
        <Typography variant="h6">
          To view and edit the modules visit{' '}
          <a
            href="https://allara-dev.sanity.studio/desk/module"
            target="_blank"
            rel="noreferrer"
          >
            Sanity
          </a>
          .
        </Typography>
        <form>
          <Grid container spacing={3}>
            <Grid item>
              <FormControl required className="select-form-control">
                <InputLabel id="module-label">Module</InputLabel>
                <Select
                  labelId="module-label"
                  value={moduleValue}
                  onChange={handleChangeModuleValue}
                  label="Module"
                >
                  {modules.map((module) => (
                    <MenuItem key={module.id} value={module.id}>
                      {module.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Tooltip
                arrow
                title="Applicability condition is the condition or event which will trigger the first action in the module. If you would like the first action in the module to execute immediately leave this blank"
              >
                <FormControl className="select-form-control">
                  <InputLabel id="applicability-label">
                    Applicability Condition
                  </InputLabel>
                  <Select
                    labelId="applicability-label"
                    value={applicabilityConditionValue}
                    onChange={handleChangeApplicabilityConditionValue}
                    label="Applicability Condition"
                  >
                    <MenuItem value="">None</MenuItem>
                    {conditions.map((condition) => (
                      <MenuItem key={condition.code} value={condition.code}>
                        {condition.code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                arrow
                title="Offset delay is the number of days to wait until executing the first action in the module (i.e. wait 30 days to follow up). Do not use this and an applicability condition. Note: if a follow up appointment is in 30 days but you want the patient to schedule 7 days in advance the correct offset would be 23 days."
              >
                <TextField
                  label="Offset Delay (in Days)"
                  className=""
                  inputRef={offsetInputRef}
                  type="number"
                />
              </Tooltip>
            </Grid>
          </Grid>
          <div className="d-flex justify-content-end mt-2">
            <Button
              variant="contained"
              className="submit-button"
              size="large"
              disabled={!moduleValue}
              onClick={handleSubmit}
            >
              Add to Timeline
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default AddModuleDialog

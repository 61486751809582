export const Conditions = '/api/v1/caretimeline/conditions'

export const AddModule = '/api/v1/caretimeline/modification/add/module'

export const TimelineModules = '/api/v1/sanity/timeline/modules'

export const UpdateAction = (id: string, type: string): string =>
  `/api/v1/caretimeline/modification/action/${type}/${id}`
export const DeleteAction = (id: string, type: string): string =>
  `/api/v1/caretimeline/modification/action/${type}/${id}`

import './styles.scss'

export interface LoaderProps {
  variant?: string
}

const Loader: React.FC<LoaderProps> = ({ variant = 'primary' }) => {
  return (
    <div className="loader">
      <div className={`dot bg-${variant}`} />
      <div className={`dot bg-${variant}`} />
      <div className={`dot bg-${variant}`} />
      <div className={`dot bg-${variant}`} />
      <div className={`dot bg-${variant}`} />
    </div>
  )
}

export default Loader

import { WaitlistUser } from 'api/admin/routes'
import { WaitlistUserDto } from 'containers/Dashboard/types'

import { getAxiosConfig } from 'utils/api'
import { useAlertContext } from 'context/AlertContext'
import { useAuth0 } from '@auth0/auth0-react'
import axios, { AxiosResponse } from 'axios'

interface UsePatientsInterface {
  updateWaitlistUser: (
    waitlistUser: WaitlistUserDto
  ) => Promise<AxiosResponse<string> | null>
}

const useWaitlistUsers = (): UsePatientsInterface => {
  const { setAlertText } = useAlertContext()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const updateWaitlistUser = async (
    waitlistUser: WaitlistUserDto
  ): Promise<AxiosResponse<string> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}${WaitlistUser}`,
        waitlistUser,
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to update waitlist user!')
        } else {
          setAlertText(err.message)
        }

        return null
      })
  }

  return {
    updateWaitlistUser,
  }
}

export default useWaitlistUsers

import {
  Patient as PatientRoute,
  PatientProvider as PatientProviderRoute,
  Pregnancy as PregnancyRoute,
} from 'api/admin/routes'
import { Patient, PatientDto } from 'containers/Dashboard/types'
import {
  useMutation,
  UseMutateFunction,
  useQuery,
  useQueryClient,
} from 'react-query'
import {
  getAxiosConfig,
  QUERY_KEYS,
  UpdatePatientProviderParams,
} from 'utils/api'
import axios, { AxiosResponse } from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import { useAlertContext } from 'context/AlertContext'
import { useInfoAlertContext } from 'context/InfoAlertContext'

interface UsePatientInterface {
  isLoading: boolean
  error: unknown
  patient?: Patient
  handleUpdatePatient: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    PatientDto,
    unknown
  >
  handleUpdatePatientProvider: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    UpdatePatientProviderParams,
    unknown
  >
  fetchPregnancySurveyLink: () => Promise<string | undefined>
}

const usePatient = (id?: string): UsePatientInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { setInfoAlertText } = useInfoAlertContext()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const fetchPatient = async (): Promise<Patient | undefined> => {
    if (!isAuthenticated || !id) {
      return
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${PatientRoute(id)}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json()
        } else {
          return
        }
      })
      .catch(() => {
        return
      })
  }

  const {
    isLoading,
    error,
    data: patient,
  } = useQuery([QUERY_KEYS.PATIENT, id], fetchPatient)

  const fetchPregnancySurveyLink = async (): Promise<string | undefined> => {
    if (!isAuthenticated || !id) {
      return
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${PregnancyRoute(id)}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json()
          return data.url
        } else {
          return
        }
      })
      .catch(() => {
        return
      })
  }

  const updatePatient = async (
    patient: PatientDto
  ): Promise<AxiosResponse<string> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    const id = patient.id
    delete patient.id
    return axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}${PatientRoute(id)}`,
        patient,
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to update patients!')
        } else {
          setAlertText(err.message)
        }

        return null
      })
  }

  const mutateUpdate = useMutation(updatePatient, {
    onSuccess: () => setInfoAlertText('Changes Saved!'),
    onSettled: () => queryClient.invalidateQueries([QUERY_KEYS.PATIENT, id]),
    onMutate: async (patient: PatientDto) => {
      await queryClient.cancelQueries([QUERY_KEYS.PATIENT, id])

      const previousData = queryClient.getQueryData<Patient>([
        QUERY_KEYS.PATIENT,
        id,
      ])

      if (previousData) {
        queryClient.setQueriesData<Patient>([QUERY_KEYS.PATIENT, id], {
          ...previousData,
          ...patient,
          waitlistUser: {
            activeCampaignContactId:
              previousData.waitlistUser.activeCampaignContactId,
            state:
              patient.waitlistUser?.state ?? previousData.waitlistUser.state,
            dob: patient.waitlistUser?.dob ?? previousData.waitlistUser.dob,
          },
        })
      }

      return { previousData }
    },
  })

  const handleUpdatePatient = mutateUpdate.mutate

  const updatePatientProvider = async (
    params: UpdatePatientProviderParams
  ): Promise<AxiosResponse<string> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${PatientProviderRoute}/${params.patientId}?type=${params.type}`,
        { provider: params.provider },
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to update patient providers!')
        } else {
          setAlertText(err.message)
        }

        return null
      })
  }

  const mutateUpdateProvider = useMutation(updatePatientProvider, {
    onSuccess: () => setInfoAlertText('Changes Saved!'),
    onSettled: () => queryClient.invalidateQueries([QUERY_KEYS.PATIENT, id]),
  })

  const handleUpdatePatientProvider = mutateUpdateProvider.mutate

  return {
    isLoading,
    error,
    patient,
    handleUpdatePatient,
    fetchPregnancySurveyLink,
    handleUpdatePatientProvider,
  }
}

export default usePatient

import { memo } from 'react'
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

export interface AnalyticsNumberCardProps {
  title: string
  description?: string
  number?: number | string
  tableData?: CardTableProps[]
  onDownload?: () => void
}

export interface CardTableProps {
  label: string
  value: number | string
  description?: string
}

const AnalyticsNumberCard: React.FC<AnalyticsNumberCardProps> = ({
  title,
  number,
  tableData,
  description,
}) => {
  const tableHasDescriptionColumn =
    tableData && tableData.find((row) => row.description !== undefined)

  return (
    <CardContent>
      <Typography variant="h6" className="analytics-card-title">
        {title}
      </Typography>
      {description && (
        <Typography variant="subtitle2">{description}</Typography>
      )}
      {number && <Typography variant="h4">{number}</Typography>}
      {tableData && (
        <Table className="analytics-card-table">
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell>Value</TableCell>
              {tableHasDescriptionColumn && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.label}>
                <TableCell>{row.label}</TableCell>
                <TableCell>{row.value}</TableCell>
                {tableHasDescriptionColumn && (
                  <TableCell>{row.description ?? ''}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </CardContent>
  )
}

export default memo(AnalyticsNumberCard)

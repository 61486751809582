import { Drawer, List, ListItem } from '@mui/material'
import AppLogo from 'components/AppLogo'
import {
  DeveloperRoutes,
  ExternalRoutes,
  RoutePaths,
} from 'containers/Core/Routes'
import { Permissions } from 'containers/Core/utils'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { useLocation } from 'react-router'
import SidebarLink from './SidebarLink'

const Sidebar: React.FC = () => {
  const location = useLocation()
  const { permissions } = useAuthContext()

  if (!permissions.length) {
    return <></>
  }

  return (
    <div>
      <Drawer
        className="sidebar"
        variant="permanent"
        classes={{ paper: 'paper' }}
        anchor="left"
      >
        <div className="spacer">
          <div className="vertical-align">
            <AppLogo />
          </div>
        </div>
        <List component="nav" classes={{ root: 'overflow' }}>
          <ListItem classes={{ root: 'sidebar-header sidebar-item' }}>
            <div>OVERVIEW</div>
            <div className={'break-line'}></div>
          </ListItem>
          <SidebarLink
            to={RoutePaths.DASHBOARD_PATIENTS}
            text="Patients"
            active={location.pathname.includes(RoutePaths.DASHBOARD_PATIENTS)}
            hasPermission={permissions.includes(Permissions.READ_PATIENTS)}
          />
          <SidebarLink
            to={RoutePaths.DASHBOARD_PROVIDERS}
            text="Providers"
            hasPermission={permissions.includes(Permissions.READ_PROVIDERS)}
          />
          <SidebarLink
            to={RoutePaths.DASHBOARD_ELIGIBLE_INSURANCES}
            text="Eligible Insurances"
            hasPermission={permissions.includes(
              Permissions.READ_ELIGIBLE_INSURANCES
            )}
          />
          <SidebarLink
            to={RoutePaths.DASHBOARD_ANALYTICS}
            text="Analytics"
            hasPermission={permissions.includes(Permissions.READ_ANALYTICS)}
          />
          <ListItem classes={{ root: 'sidebar-header sidebar-item' }}>
            <div>LINKS</div>
            <div className={'break-line'}></div>
          </ListItem>
          <SidebarLink to={ExternalRoutes.ELATION} text="Elation" external />
          <SidebarLink to={ExternalRoutes.STRIPE} text="Stripe" external />
          <SidebarLink
            to={ExternalRoutes.ACTIVE_CAMPAIGN}
            text="Active Campaign"
            external
          />
          <SidebarLink
            to={ExternalRoutes.COMMUNITY}
            text="Community"
            external
          />
          <SidebarLink to={ExternalRoutes.NOTION} text="Notion" external />
          <SidebarLink to={ExternalRoutes.UNBOUNCE} text="Unbounce" external />
          <ListItem classes={{ root: 'sidebar-header sidebar-item' }}>
            <div>DEVELOPER LINKS</div>
            <div className={'break-line'}></div>
          </ListItem>
          <SidebarLink to={DeveloperRoutes.GITHUB} text="Github" external />
          <SidebarLink to={DeveloperRoutes.VERCEL} text="Vercel" external />
          <SidebarLink to={DeveloperRoutes.HEROKU} text="Heroku" external />
          <SidebarLink to={DeveloperRoutes.APTIBLE} text="Aptible" external />
          <SidebarLink
            to={DeveloperRoutes.CATALYST_DOCS}
            text="Catalyst Docs"
            external
          />
          <SidebarLink
            to={DeveloperRoutes.STRIPE_DOCS}
            text="Stripe Docs"
            external
          />
          <SidebarLink
            to={DeveloperRoutes.ELATION_DOCS}
            text="Elation Docs"
            external
          />
          <SidebarLink
            to={DeveloperRoutes.ACTIVE_CAMPAIGN_DOCS}
            text="Active Campaign Docs"
            external
          />
        </List>
      </Drawer>
    </div>
  )
}

export default Sidebar

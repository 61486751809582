import { BreakdownDataSection } from 'containers/Dashboard/Analytics/types'

export const percentage = (n: number, total: number): string => {
  const p = (n / total) * 100
  return `${p.toFixed(2)}%`
}

export const computeTotal = (dataSection: BreakdownDataSection): number => {
  return Object.values(dataSection).reduce((total, n) => total + n, 0)
}

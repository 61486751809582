import { useAuth0 } from '@auth0/auth0-react'
import {
  Conditions as ConditionsRoute,
  TimelineModules as TimelineModulesRoute,
} from 'api/timeline/routes'
import { TimelineCondition, TimelineModule } from 'containers/Dashboard/types'
import { useAlertContext } from 'context/AlertContext'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { getAxiosConfig, QUERY_KEYS } from 'utils/api'
import { alphabetize } from 'utils/sort'

interface UseTimelineModulesInterface {
  modules: TimelineModule[]
  conditions: TimelineCondition[]
}

const useTimelineModules = (): UseTimelineModulesInterface => {
  const { setAlertText } = useAlertContext()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const fetchModules = async (): Promise<TimelineModule[]> => {
    if (!isAuthenticated) {
      return []
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${TimelineModulesRoute}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          setAlertText(`Error fetching timeline modules (${res.status})`)
          return []
        }
      })
      .catch(() => {
        setAlertText('Error fetching timeline modules')
        return []
      })
  }

  const { data } = useQuery(QUERY_KEYS.MODULES, fetchModules, {
    staleTime: Infinity,
  })

  const modules = useMemo(
    () => data?.sort((a, b) => alphabetize(a.title, b.title)) ?? [],
    [data]
  )

  const fetchConditions = async (): Promise<TimelineCondition[]> => {
    if (!isAuthenticated) {
      return []
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${ConditionsRoute}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          setAlertText(`Error fetching timeline conditions (${res.status})`)
          return []
        }
      })
      .catch(() => {
        setAlertText('Error fetching timeline conditions')
        return []
      })
  }

  const { data: conditionsData } = useQuery(
    QUERY_KEYS.CONDITIONS,
    fetchConditions,
    { staleTime: Infinity }
  )

  const conditions = useMemo(
    () => conditionsData?.sort((a, b) => alphabetize(a.code, b.code)) ?? [],
    [conditionsData]
  )

  return { modules, conditions }
}

export default useTimelineModules

import { createContext, memo, ReactNode, useCallback, useState } from 'react'
import AlertDialog from './AlertDialog'

export interface AlertContextInterface {
  setAlertText: (value: string) => void
}

export const AlertContext = createContext<AlertContextInterface>(
  {} as AlertContextInterface
)

interface AlertProviderProps {
  children: ReactNode
}

export const AlertProvider: React.FC<AlertProviderProps> = memo(
  ({ children }) => {
    const [alertText, setAlertText] = useState<string>('')

    const handleCloseAlert = useCallback(() => {
      setAlertText('')
    }, [setAlertText])

    return (
      <AlertContext.Provider value={{ setAlertText }}>
        <AlertDialog
          open={alertText !== ''}
          message={alertText}
          close={handleCloseAlert}
        />
        {children}
      </AlertContext.Provider>
    )
  }
)

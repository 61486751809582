import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import useEligibleInsurances, { Insurance } from 'hooks/useEligibleInsurance'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { STATES } from 'utils/states'
import { State } from '../types'

interface NewEligibleInsuranceDialogProps {
  open: boolean
  insurance?: Insurance
  handleClose: () => void
}

const NewEligibleInsuranceDialog: React.FC<NewEligibleInsuranceDialogProps> = ({
  open,
  insurance,
  handleClose,
}) => {
  const { handleAddState, handleAddEligibleInsurance } = useEligibleInsurances()
  const titleInputRef = useRef<HTMLInputElement>(null)
  const providerInputRef = useRef<HTMLInputElement>(null)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [states, setStates] = useState<State[]>([])

  useEffect(() => {
    if (insurance?.states) {
      setStates(insurance.states)
    }

    return () => setStates([])
  }, [insurance])

  useEffect(() => computeDisabled(), [states.length])

  const newStates = useMemo(
    () =>
      insurance
        ? states.filter((state) => insurance.states.indexOf(state) === -1)
        : states,
    [states, insurance]
  )

  const handleChangeState = (_: React.ChangeEvent<unknown>, value: State[]) => {
    setStates(value)
  }

  const computeDisabled = () => {
    const allRequiredFieldsSet =
      (insurance
        ? true
        : titleInputRef.current?.value && providerInputRef.current?.value) &&
      newStates.length
    setDisabled(!Boolean(allRequiredFieldsSet))
  }

  const handleSubmit = () => {
    if (insurance && newStates.length) {
      for (const state of newStates) {
        const insuranceDto = {
          title: insurance.title,
          provider: insurance.provider,
          state,
        }
        handleAddState(insuranceDto)
      }
    } else if (
      titleInputRef.current?.value &&
      providerInputRef.current?.value &&
      newStates.length
    ) {
      for (const state of newStates) {
        const insuranceDto = {
          title: titleInputRef.current.value,
          provider: providerInputRef.current.value,
          state,
        }
        handleAddEligibleInsurance(insuranceDto)
      }
    }

    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aira-labelledby="add-eligible-insurance-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="add-eligible-insurance-dialog-title">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" className="dialog-title-text">
          Add New {insurance && 'State to'} Insurance Provider
        </Typography>
        <Typography variant="h6">
          This information is used to determine if a patient is eligible for the
          insurance program. The title and code cannot be changed after it has
          been created.
        </Typography>
        <form className="new-asset-form">
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <Tooltip
                arrow
                title="Human readable title for the insurance (this cannot be changed)"
              >
                {insurance ? (
                  <TextField
                    fullWidth
                    label="Title"
                    value={insurance.title}
                    className="new-asset-textfield"
                    disabled
                  />
                ) : (
                  <TextField
                    fullWidth
                    required
                    label="Title"
                    className="new-asset-textfield"
                    inputRef={titleInputRef}
                    onChange={computeDisabled}
                  />
                )}
              </Tooltip>
            </Grid>
            <Grid item sm={6}>
              <Tooltip
                arrow
                title="Value of the insurance from the formsort dropdown (this cannot be changed)"
              >
                {insurance ? (
                  <TextField
                    fullWidth
                    label="Code"
                    value={insurance.provider}
                    className="new-asset-textfield"
                    disabled
                  />
                ) : (
                  <TextField
                    fullWidth
                    required
                    label="Code"
                    className="new-asset-textfield"
                    inputRef={providerInputRef}
                    onChange={computeDisabled}
                  />
                )}
              </Tooltip>
            </Grid>
            <Grid item sm={12}>
              <div className="add-state-list">
                <Autocomplete
                  multiple
                  autoHighlight
                  options={STATES}
                  getOptionLabel={(option) => option.fullName}
                  getOptionSelected={(option, value) =>
                    option.code === value.code
                  }
                  value={states}
                  onChange={handleChangeState}
                  filterSelectedOptions
                  renderTags={(tagValue) =>
                    tagValue.map((state) => (
                      <Chip
                        key={state.code}
                        label={state.fullName}
                        className="state-chip"
                        disabled={
                          insurance ? insurance.states.includes(state) : false
                        }
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="States"
                      placeholder="Enter State name..."
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
          <div className="action-buttons">
            <Button
              variant="contained"
              className="cancel-button"
              size="large"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="submit-button"
              size="large"
              disabled={disabled}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default NewEligibleInsuranceDialog

import {
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  Delete as DeleteIcon,
  SkipNext as SkipNextIcon,
  SkipPrevious as SkipPreviousIcon,
} from '@material-ui/icons'
import useTimeline from 'hooks/useTimeline'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Permissions } from 'containers/Core/utils'
import { useCallback, useMemo, useState } from 'react'
import { ConciergeAction } from 'containers/Dashboard/types'
import ConfirmDialog from 'components/ConfirmDialog'

interface ConciergeActionsTableProps {
  patientId: string
}

const ConciergeActionsTable: React.FC<ConciergeActionsTableProps> = ({
  patientId,
}) => {
  const { permissions } = useAuthContext()
  const {
    isLoading,
    conciergeActions,
    handleDeleteAction,
    handleUpdateActionStatus,
  } = useTimeline(patientId)
  const [showComplete, setShowComplete] = useState<boolean>(false)
  const [showAdvanced, setShowAdvanced] = useState<boolean>(true)
  const [deleteAction, setDeleteAction] = useState<ConciergeAction | null>(null)
  const [advanceAction, setAdvanceAction] = useState<{
    action: ConciergeAction
    status: 'inactive' | 'active' | 'complete'
  } | null>(null)

  const closeConfirmDeleteDialog = () => setDeleteAction(null)
  const closeConfirmStatusDialog = () => setAdvanceAction(null)
  const toggleShowComplete = () => setShowComplete((current) => !current)
  const toggleShowAdvanced = () => setShowAdvanced((current) => !current)

  const handleDelete = useCallback(() => {
    if (deleteAction) {
      handleDeleteAction({ type: 'concierge', id: deleteAction.id })
    }
  }, [deleteAction])

  const handleUpdateStatus = useCallback(() => {
    if (advanceAction) {
      handleUpdateActionStatus({
        status: advanceAction.status,
        id: advanceAction.action.id,
        type: 'concierge',
      })
    }
  }, [advanceAction])

  const orderedConciergeActions = useMemo(() => {
    const completedActions = []
    const activeActions = []
    const inactiveActions = []

    for (const conciergeAction of conciergeActions) {
      switch (conciergeAction.status) {
        case 'complete':
          if (showComplete) {
            completedActions.push(conciergeAction)
          }

          break
        case 'active':
          activeActions.push(conciergeAction)
          break
        case 'inactive':
          inactiveActions.push(conciergeAction)
          break
      }
    }

    return [...completedActions, ...activeActions, ...inactiveActions]
  }, [conciergeActions, showComplete])

  return (
    <>
      <TableContainer className="table-container">
        <div className="d-flex justify-content-between">
          <Typography variant="h5">Concierge Actions</Typography>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={showAdvanced}
                  onChange={toggleShowAdvanced}
                  name="show-details"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />
              }
              label="Show Conditions"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showComplete}
                  onChange={toggleShowComplete}
                  name="show-complete"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Show Completed"
              labelPlacement="start"
            />
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Definition</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created</TableCell>
              {showAdvanced && (
                <>
                  <TableCell>Applicability Condition</TableCell>
                  <TableCell>Complete Condition</TableCell>
                </>
              )}
              {showComplete && <TableCell>Completed</TableCell>}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedConciergeActions.map((conciergeAction) => {
              const created = new Date(conciergeAction.created)
              const completed = conciergeAction.complete
                ? new Date(conciergeAction.complete)
                : undefined

              return (
                <TableRow key={conciergeAction.id}>
                  <TableCell>{conciergeAction.definition}</TableCell>
                  <TableCell>{conciergeAction.status}</TableCell>
                  <TableCell>
                    {created.toLocaleDateString()}{' '}
                    {created.toLocaleTimeString()}
                  </TableCell>
                  {showAdvanced && (
                    <>
                      <TableCell>
                        {conciergeAction.applicabilityCondition?.code ?? ''}
                      </TableCell>
                      <TableCell>
                        {conciergeAction.completeCondition?.code ?? ''}
                      </TableCell>
                    </>
                  )}
                  {showComplete && (
                    <TableCell>
                      {completed
                        ? `${completed.toLocaleDateString()} ${completed.toLocaleTimeString()}`
                        : ''}
                    </TableCell>
                  )}
                  <TableCell align="right">
                    {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                      conciergeAction.status !== 'complete' && (
                        <IconButton
                          className="list-button"
                          size="small"
                          onClick={() =>
                            setAdvanceAction({
                              action: conciergeAction,
                              status: 'inactive',
                            })
                          }
                          disabled={conciergeAction.status === 'inactive'}
                        >
                          <SkipPreviousIcon />
                        </IconButton>
                      )}
                    {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                      conciergeAction.status !== 'complete' && (
                        <IconButton
                          className="list-button"
                          size="small"
                          onClick={() =>
                            setAdvanceAction({
                              action: conciergeAction,
                              status:
                                conciergeAction.status === 'active'
                                  ? 'complete'
                                  : 'active',
                            })
                          }
                        >
                          <SkipNextIcon />
                        </IconButton>
                      )}
                    {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                      conciergeAction.status !== 'complete' && (
                        <IconButton
                          className="list-button"
                          size="small"
                          onClick={() => setDeleteAction(conciergeAction)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {!isLoading && conciergeActions.length === 0 && (
          <div className="text-center">No concierge actions</div>
        )}
        <ConfirmDialog
          key={deleteAction?.id ?? 'no-delete-action'}
          open={deleteAction !== null}
          text={`Are you sure you want to delete ${deleteAction?.definition} (Status: ${deleteAction?.status})`}
          titleText="Confirm Delete Concierge Action"
          helperText="Note: Some actions are related (i.e. book-followup-md and join-followup-md. Deleting just one can cause issues. Proceed with caution."
          onConfirm={handleDelete}
          handleClose={closeConfirmDeleteDialog}
        />
        <ConfirmDialog
          key={advanceAction?.action.id ?? 'advance-action'}
          open={advanceAction !== null}
          text={`Are you sure you want to update the status of ${advanceAction?.action.definition} from ${advanceAction?.action.status} to ${advanceAction?.status}?`}
          titleText="Confirm Update Concierge Action Status"
          helperText={
            advanceAction?.status === 'complete'
              ? 'Note: This action cannot be undone'
              : undefined
          }
          onConfirm={handleUpdateStatus}
          handleClose={closeConfirmStatusDialog}
        />
      </TableContainer>
    </>
  )
}

export default ConciergeActionsTable

import { useAuth0 } from '@auth0/auth0-react'
import {
  ConciergeAction,
  ModuleAction,
  Patient,
  PatientAction,
  ProviderAction,
  SystemAction,
  TimelineActionType,
  TimelineCondition,
} from 'containers/Dashboard/types'
import { useEffect } from 'react'
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { getAxiosConfig, QUERY_KEYS } from 'utils/api'
import { Patients as PatientsRoute } from 'api/admin/routes'
import { useAlertContext } from 'context/AlertContext'
import axios, { AxiosResponse } from 'axios'
import {
  AddModule as AddModuleRoute,
  DeleteAction as DeleteActionRoute,
  UpdateAction,
} from 'api/timeline/routes'
import produce from 'immer'

interface UseTimelineInterface {
  isLoading: boolean
  error: unknown
  moduleActions: ModuleAction[]
  systemActions: SystemAction[]
  patientActions: PatientAction[]
  providerActions: ProviderAction[]
  conciergeActions: ConciergeAction[]
  handleDeleteAction: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    DeleteActionInterface,
    unknown
  >
  handleAddModule: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    AddModuleInterface,
    unknown
  >
  handleUpdateActionStatus: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    UpdateStatusInterface,
    unknown
  >
}

interface DeleteActionInterface {
  id: string
  type: TimelineActionType
}

interface AddModuleInterface {
  moduleId: string
  user: Patient
  applicabilityCondition?: TimelineCondition
  offsetDelay?: number
}

interface UpdateStatusInterface {
  id: string
  status: 'active' | 'inactive' | 'complete' | 'archived'
  type: TimelineActionType
}

interface TimelineInterface {
  moduleActions: ModuleAction[]
  systemActions: SystemAction[]
  patientActions: PatientAction[]
  providerActions: ProviderAction[]
  conciergeActions: ConciergeAction[]
}

const useTimeline = (patientId: string): UseTimelineInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const fetchTimeline = async (): Promise<TimelineInterface> => {
    const defaultRetValue = {
      moduleActions: [],
      systemActions: [],
      patientActions: [],
      providerActions: [],
      conciergeActions: [],
    }

    if (!isAuthenticated) {
      return defaultRetValue
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${PatientsRoute}/timeline/${patientId}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          setAlertText(`Error loading timeline (${res.status})`)
          return defaultRetValue
        }
      })
      .catch(() => {
        setAlertText('Error loading timeline')
        return defaultRetValue
      })
  }

  const { isLoading, error, data } = useQuery(
    [QUERY_KEYS.TIMELIME, patientId],
    fetchTimeline
  )

  const deleteAction = async (
    deleteOptions: DeleteActionInterface
  ): Promise<AxiosResponse<string> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return axios
      .delete<string>(
        `${process.env.REACT_APP_SERVER_URL}${DeleteActionRoute(
          deleteOptions.id,
          deleteOptions.type
        )}`,
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to delete actions!')
        } else {
          setAlertText(err.message)
        }

        return null
      })
  }

  const mutateDelete = useMutation(deleteAction, {
    onSettled: () =>
      queryClient.invalidateQueries([QUERY_KEYS.TIMELIME, patientId]),
    onMutate: async (deleteOptions: DeleteActionInterface) => {
      await queryClient.cancelQueries([QUERY_KEYS.TIMELIME, patientId])
      const previousData = queryClient.getQueryData<TimelineInterface>([
        QUERY_KEYS.TIMELIME,
        patientId,
      ])

      if (previousData) {
        const nextState = produce(previousData, (draftState) => {
          let actions

          if (deleteOptions.type === 'concierge') {
            actions = draftState.conciergeActions
          } else if (deleteOptions.type === 'module') {
            actions = draftState.moduleActions
          } else if (deleteOptions.type === 'patient') {
            actions = draftState.patientActions
          } else if (deleteOptions.type === 'provider') {
            actions = draftState.providerActions
          } else if (deleteOptions.type === 'system') {
            actions = draftState.systemActions
          }

          actions?.filter((a) => a.id !== deleteOptions.id)
        })
        queryClient.setQueryData([QUERY_KEYS.TIMELIME, patientId], nextState)
      }

      return { previousData }
    },
  })

  const handleDeleteAction = mutateDelete.mutate

  const addModule = async (
    addModuleDto: AddModuleInterface
  ): Promise<AxiosResponse<string> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${AddModuleRoute}`,
        addModuleDto,
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to update actions!')
        } else {
          setAlertText(err.message)
        }

        return null
      })
  }

  const mutateAdd = useMutation(addModule, {
    onSettled: () =>
      queryClient.invalidateQueries([QUERY_KEYS.TIMELIME, patientId]),
    onMutate: async (addModuleDto: AddModuleInterface) => {
      await queryClient.cancelQueries([QUERY_KEYS.TIMELIME, patientId])
      const previousData = queryClient.getQueryData<TimelineInterface>([
        QUERY_KEYS.TIMELIME,
        patientId,
      ])

      if (previousData) {
        const nextState = produce(previousData, (draft) => {
          draft.moduleActions.push({
            moduleId: addModuleDto.moduleId,
            id: `${draft.moduleActions.length}`,
            user: addModuleDto.user.id,
            created: new Date().toISOString(),
            status: 'active',
            definition: '',
          })
        })
        queryClient.setQueryData([QUERY_KEYS.TIMELIME, patientId], nextState)
      }

      return { previousData }
    },
  })

  const handleAddModule = mutateAdd.mutate

  const updateActionStatus = async (
    updateOptions: UpdateStatusInterface
  ): Promise<AxiosResponse<string> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return axios
      .patch<string>(
        `${process.env.REACT_APP_SERVER_URL}${UpdateAction(
          updateOptions.id,
          updateOptions.type
        )}`,
        { status: updateOptions.status },
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to update actions!')
        } else {
          setAlertText(err.message)
        }

        return null
      })
  }

  const mutateUpdate = useMutation(updateActionStatus, {
    onSettled: () =>
      queryClient.invalidateQueries([QUERY_KEYS.TIMELIME, patientId]),
    onMutate: async (updateOptions: UpdateStatusInterface) => {
      await queryClient.cancelQueries([QUERY_KEYS.TIMELIME, patientId])
      const previousData = queryClient.getQueryData<TimelineInterface>([
        QUERY_KEYS.TIMELIME,
        patientId,
      ])

      if (previousData) {
        const nextState = produce(previousData, (draftState) => {
          let actions

          if (updateOptions.type === 'concierge') {
            actions = draftState.conciergeActions
          } else if (updateOptions.type === 'module') {
            actions = draftState.moduleActions
          } else if (updateOptions.type === 'patient') {
            actions = draftState.patientActions
          } else if (updateOptions.type === 'provider') {
            actions = draftState.providerActions
          } else if (updateOptions.type === 'system') {
            actions = draftState.systemActions
          }

          actions?.filter((a) => a.id !== updateOptions.id)
        })
        queryClient.setQueryData([QUERY_KEYS.TIMELIME, patientId], nextState)
      }

      return { previousData }
    },
  })

  const handleUpdateActionStatus = mutateUpdate.mutate

  useEffect(() => {
    queryClient.invalidateQueries(QUERY_KEYS.TIMELIME)
  }, [patientId])

  return {
    isLoading,
    error,
    moduleActions: data?.moduleActions ?? [],
    systemActions: data?.systemActions ?? [],
    patientActions: data?.patientActions ?? [],
    providerActions: data?.providerActions ?? [],
    conciergeActions: data?.conciergeActions ?? [],
    handleDeleteAction,
    handleAddModule,
    handleUpdateActionStatus,
  }
}

export default useTimeline

import { memo } from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert from '@material-ui/lab/Alert'

interface AlertDialogProps {
  open: boolean
  message: string
  close: () => void
}

const AlertDialog: React.FC<AlertDialogProps> = ({ open, message, close }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={close}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MuiAlert elevation={6} variant="filled" onClose={close} severity="error">
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default memo(AlertDialog)

import { ImageProps } from 'react-bootstrap'
import { ReactComponent as Logo } from 'assets/images/logo.svg'

const AppLogo: React.FC<ImageProps> = ({ className = '' }) => {
  return (
    <div className={'logo-container ' + className}>
      <a href="/">
        <Logo className="logo" />
      </a>
    </div>
  )
}

export default AppLogo

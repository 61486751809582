import { AxiosRequestConfig } from 'axios'
import { Provider } from 'containers/Dashboard/types'

export const QUERY_KEYS = {
  STATES: 'states',
  MODULES: 'modules',
  PATIENT: 'patient',
  PATIENTS: 'patients',
  TIMELIME: 'timeline',
  ANALYTICS: 'analytics',
  PROVIDERS: 'providers',
  CONDITIONS: 'conditions',
  INACTIVE_PATIENTS: 'inactive-patients',
  APPOINTMENT_TYPES: 'appointment-types',
  ELIGIBLE_INSURANCES: 'eligible-insurances',
  WAITLIST_USERS: 'waitlist-users',
}

export type UpdatePatientProviderParams = {
  patientId: string
  type: 'medical-provider' | 'dietician'
  provider: Provider | null
}

export function getAxiosConfig(accessToken: string): AxiosRequestConfig {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }
}

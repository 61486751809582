import { ListItem, ListItemText } from '@mui/material'
import { ChevronRight } from '@material-ui/icons'
import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'

interface SidebarLinkProps {
  to: string
  text: string
  active?: boolean
  hidden?: boolean
  subLink?: boolean
  external?: boolean
  hasPermission?: boolean
}

const SidebarLink: React.FC<SidebarLinkProps> = ({
  to,
  text,
  active = false,
  hidden = false,
  subLink = false,
  external = false,
  hasPermission = false,
}) => {
  const history = useHistory()
  const location = useLocation()

  const itemClass =
    location.pathname === to || active
      ? 'sidebar-item selected-item'
      : 'sidebar-item'

  const subLinkClass = subLink ? 'sidebar-item-sublink' : ''

  const textClass = subLink
    ? 'sidebar-item-text sidebar-item-text-sublink'
    : 'sidebar-item-text'

  const navigate = useCallback(() => {
    if (external) {
      window.open(to, '_blank')
    } else {
      history.push(to)
    }
  }, [])

  if (hidden) {
    return <></>
  } else {
    return (
      <ListItem
        button
        classes={{ root: `${itemClass} ${subLinkClass}` }}
        onClick={navigate}
        disabled={!hasPermission && !external}
      >
        <ListItemText
          primary={text}
          classes={{ primary: textClass }}
          onClick={navigate}
        />
        <ChevronRight classes={{ root: 'chevron' }} onClick={navigate} />
      </ListItem>
    )
  }
}

export default SidebarLink

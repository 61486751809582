import Loader from 'components/Loader'
import { RoutePaths } from 'containers/Core/Routes'
import { Permissions } from 'containers/Core/utils'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import usePatient from 'hooks/usePatient'
import { Redirect, useParams } from 'react-router'
import PatientSearch from './PatientSearch'
import PatientTimeline from './PatientTimeline'
import PatientView from './PatientView'
import { Container } from 'react-bootstrap'
import AppLogo from 'components/AppLogo'

interface PatientsParams {
  view?: string
  patientId?: string
}

const PatientRouter: React.FC = () => {
  const { permissions } = useAuthContext()
  const { view, patientId } = useParams<PatientsParams>()
  const { isLoading, error, patient } = usePatient(patientId)

  // Check user has permissions to see this page
  if (!permissions.length) {
    return (
      <Container className="d-flex flex-column justify-content-center align-items-center height-full">
        <p className="h1 text-center pb-6">Checking permissions...</p>
        <Loader />
      </Container>
    )
  } else if (!permissions.includes(Permissions.READ_PATIENTS)) {
    return (
      <Container className="d-flex flex-column justify-content-center align-items-center height-full">
        <AppLogo className="position-absolute top-3 " />
        <p className="h1 text-center text-danger pb-6">
          You don&apos;t have permissions to view patients
        </p>
      </Container>
    )
  }

  // Has permissions to view this page
  if (!patientId) {
    // If no route params then show search to select a patient
    return <PatientSearch />
  } else if (isLoading) {
    // If patientId exists then we need to load it, wait
    return <Loader />
  } else if (error) {
    // If patientId exists and an error then display to user
    return <div>ERROR</div>
  } else if (!patient) {
    // If patientId exists but patient does not then wait
    // If error is undefined and isLoading is false this should
    //  never run but just in case and to ensure patient is
    //  always set below, show loading spinner
    return <Loader />
  } else {
    // Patient is defined in here, render the correct page based on view
    if (!view) {
      // No view param indicates main patient view
      return (
        <PatientView
          patient={patient}
          readOnly={
            permissions.includes(Permissions.UPDATE_PATIENTS) ? false : true
          }
        />
      )
    } else if (view === 'timeline') {
      // Show timeline view
      return <PatientTimeline patient={patient} />
    } else {
      // If view is anything else then remove it, this redirect
      //  should cause the patient view above to render
      return <Redirect to={`${RoutePaths.DASHBOARD_PATIENTS}/${patientId}`} />
    }
  }
}

export default PatientRouter

import { Card, IconButton } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import { memo, useState } from 'react'

interface AnalyticsCarouselCardProps {
  components: React.ReactNode[]
}

const AnalyticsCarouselCard: React.FC<AnalyticsCarouselCardProps> = ({
  components,
}) => {
  const [screen, setScreen] = useState<number>(0)

  const nextScreen = () => {
    const newScreen = screen === components.length - 1 ? 0 : screen + 1
    setScreen(newScreen)
  }

  const prevScreen = () => {
    const newScreen = screen === 0 ? components.length - 1 : screen - 1
    setScreen(newScreen)
  }

  return (
    <div className="position-relative">
      <Card className="analytics-card analytics-card-number">
        {components[screen]}
        <div className="analytics-carousel-card-buttons">
          <IconButton aria-label="back" onClick={prevScreen} className="">
            <NavigateBefore />
          </IconButton>
          <IconButton aria-label="next" onClick={nextScreen} className="">
            <NavigateNext />
          </IconButton>
        </div>
      </Card>
    </div>
  )
}

export default memo(AnalyticsCarouselCard)

import { States as StatesRoute } from 'api/admin/routes'
import { State } from 'containers/Dashboard/types'
import { useMemo } from 'react'
import {
  useMutation,
  UseMutateFunction,
  useQuery,
  useQueryClient,
} from 'react-query'
import { getAxiosConfig, QUERY_KEYS } from 'utils/api'
import axios, { AxiosResponse } from 'axios'
import { useAlertContext } from 'context/AlertContext'
import { useAuth0 } from '@auth0/auth0-react'
import produce from 'immer'

interface UseStatesInterface {
  isLoading: boolean
  error: unknown
  states: State[]
  eligibleStates: State[]
  ineligibleStates: State[]
  eligibleNutritionStates: State[]
  handleUpdateStateEligibility: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    UpdateStateDto,
    unknown
  >
}

export type UpdateStateDto = {
  state: State
  eligible?: boolean
  eligibleNutrition?: boolean
}

const useStates = (): UseStatesInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const fetchStates = async (): Promise<State[]> => {
    if (!isAuthenticated) {
      return []
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${StatesRoute}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          setAlertText(`Error loading states (${res.status})`)
          return []
        }
      })
      .catch(() => {
        setAlertText('Error loading states')
        return []
      })
  }

  const { isLoading, error, data } = useQuery(QUERY_KEYS.STATES, fetchStates, {
    staleTime: Infinity,
  })

  const states = useMemo(() => data ?? [], [data])

  const updateState = async ({
    state,
    ...updateStateDto
  }: UpdateStateDto): Promise<AxiosResponse<string> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}${StatesRoute}/${state.code}`,
        updateStateDto,
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to add eligible states!')
        } else {
          setAlertText(err.message)
        }

        return null
      })
  }

  const mutateUpdate = useMutation(updateState, {
    onSettled: () => queryClient.invalidateQueries(QUERY_KEYS.STATES),
    onMutate: async (state: UpdateStateDto) => {
      await queryClient.cancelQueries(QUERY_KEYS.STATES)
      const previousData = queryClient.getQueryData<State[]>(QUERY_KEYS.STATES)

      if (previousData) {
        const nextState = produce(previousData, (draftState) => {
          const index = draftState.findIndex(
            (item) => item.code === state.state.code
          )
          draftState[index] = { ...draftState[index], ...state }
        })
        queryClient.setQueryData(QUERY_KEYS.STATES, nextState)
      }

      return { previousData }
    },
  })

  const handleUpdateStateEligibility = mutateUpdate.mutate

  const eligibleStates = useMemo(
    () => states.filter((s) => s.eligible === true),
    [states]
  )

  const ineligibleStates = useMemo(
    () => states.filter((s) => s.eligible === false),
    [states]
  )

  const eligibleNutritionStates = useMemo(
    () => states.filter((s) => s.eligibleNutrition),
    [states]
  )

  return {
    isLoading,
    error,
    states,
    eligibleStates,
    ineligibleStates,
    eligibleNutritionStates,
    handleUpdateStateEligibility,
  }
}

export default useStates

import { Button, Tooltip, Typography } from '@mui/material'
import DataEditRow, { DataType, UNASSIGNED_VALUE } from 'components/DataEditRow'
import { RoutePaths } from 'containers/Core/Routes'
import { useAlertContext } from 'context/AlertContext'
import usePatient from 'hooks/usePatient'
import useProviders from 'hooks/useProviders'
import produce from 'immer'
import { memo, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { STATES } from 'utils/states'
import { Patient, PatientDto } from '../types'

import './styles.scss'
import PregnancySurveyDialog from 'components/PregnancySurveyDialog'

interface PatientViewProps {
  patient: Patient
  readOnly?: boolean
}

const PatientView: React.FC<PatientViewProps> = ({
  patient,
  readOnly = true,
}) => {
  const history = useHistory()
  const { setAlertText } = useAlertContext()
  const { medicalProviders, dieticians } = useProviders()
  const {
    handleUpdatePatient,
    fetchPregnancySurveyLink,
    handleUpdatePatientProvider,
  } = usePatient(patient.id)
  const [pregnancySurveyLink, setPregnancySurveyLink] = useState<
    string | 'loading' | undefined
  >(undefined)

  const medicalProviderOptions = [
    { label: 'Unassigned', value: UNASSIGNED_VALUE },
    ...medicalProviders.map((p) => ({
      label: `${p.firstName} ${p.lastName}`,
      value: p.id,
    })),
  ]

  const dieticianOptions = [
    { label: 'Unassigned', value: UNASSIGNED_VALUE },
    ...dieticians.map((d) => ({
      label: `${d.firstName} ${d.lastName}`,
      value: d.id,
    })),
  ]

  const stateOptions = STATES.map((s) => ({ label: s.fullName, value: s.code }))

  const options: DataType[] = useMemo(() => {
    return [
      {
        label: 'ID',
        value: patient.id ?? '',
        description: 'Patient Unique ID',
        editable: false,
      },
      {
        label: 'First Name',
        value: patient.firstName ?? '',
        description:
          'Paitient First Name. Updating this will persist to Elation.',
      },
      {
        label: 'Preferred Name',
        value: patient.preferredName ?? '',
        description:
          'Paitient Preferred Name. This is what the patient likes to be called.',
      },
      {
        label: 'Last Name',
        value: patient.lastName ?? '',
        description:
          'Patient Last Name. Updating this will persist to Elation.',
      },
      {
        label: 'Email',
        value: patient.email ?? '',
        description: 'Patient Email',
        editable: false,
      },
      {
        label: 'Phone Number',
        value: patient.phoneNumber ?? '',
        description: 'Patient Phone Number',
      },
      {
        label: 'Date of Birth',
        value: patient.waitlistUser.dob ?? '',
        description: 'Patient Date of Birth',
      },
      {
        label: 'State',
        value: patient.waitlistUser.state.code ?? '',
        description: 'Patient Home State',
        options: stateOptions,
      },
      {
        label: 'Appointments',
        value: [...patient.appointments]
          .sort(
            (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
          )
          .map((appointment) => {
            return {
              label: `${appointment.type.category}: ${new Date(
                appointment.time
              ).toLocaleString()}${
                appointment.isCancelled ? ' (Cancelled)' : ''
              }`,
              value: appointment.time,
            }
          }),
        description:
          'List of Appointments for the Patient. To see more details check Source',
      },
      {
        label: 'Products',
        value: patient.previousProducts.map((previousProduct) => {
          return {
            label: `${previousProduct.product.type} (Joined: ${new Date(
              previousProduct.joined
            ).toLocaleString()})`,
            value: previousProduct.id,
          }
        }),
        description: 'List of all products for the Patient',
      },
      {
        label: 'Auth ID',
        value: patient.authId ?? '',
        description: 'Auth0 ID (cannot be changed)',
        editable: false,
      },
      {
        label: 'AC Contact ID',
        value: patient.waitlistUser.activeCampaignContactId,
        description: 'Active Campaign Contact ID (cannot be changed)',
        editable: false,
        link: `https://allarahealth.activehosted.com/app/contacts/${patient.waitlistUser.activeCampaignContactId}`,
      },
      {
        label: 'Elation ID',
        value: patient.elationId ?? '',
        description: 'Elation Patient ID (cannot be changed)',
        editable: false,
        link: patient.elationId
          ? `https://app.elationemr.com/patient/${patient.elationId}`
          : undefined,
      },
      {
        label: 'Source ID',
        value: patient.sourceHealthId ?? '',
        description: 'Source Health ID (cannot be changed)',
        editable: false,
        link: patient.sourceHealthId
          ? `https://care-ops.allarahealth.com/patients/${patient.sourceHealthId}`
          : undefined,
      },
      {
        label: 'Medical Provider',
        value: patient.medicalProvider?.id ?? '',
        description: 'Patient Primary Medical Provider',
        options: medicalProviderOptions,
      },
      {
        label: 'Dietician',
        value: patient.dietician?.id ?? '',
        description: 'Patient Primary Allara Dietician',
        options: dieticianOptions,
      },
      {
        label: 'SMS',
        value: patient.smsPreference?.sms ?? false,
        description: 'SMS Preferences for conversations with the dietician',
      },
      {
        label: 'Messaging Status',
        value: patient.messagingDeactivated ? 'Disabled' : 'Enabled',
        description:
          'When disabled, a patient will not be able to create new messages from their dashboard',
        editable: false,
      },
      {
        label: 'Customer ID',
        value: patient.stripeCustomer?.id ?? '',
        description: 'Stripe Customer ID (cannot be changed)',
        editable: false,
        link: patient.stripeCustomer?.id
          ? `https://dashboard.stripe.com/customers/${patient.stripeCustomer?.id}`
          : undefined,
      },
      {
        label: 'Product Type',
        value: patient.stripeCustomer?.product?.type ?? '',
        description: 'Stripe Product ID (cannot be changed)',
        editable: false,
      },
      {
        label: 'Tier',
        value: patient.stripeCustomer?.tier ?? '',
        description: 'Subscription Tier (e.g. Six Months)',
        editable: false,
      },
      {
        label: 'Subscription ID',
        value: patient.stripeCustomer?.subscriptionId ?? '',
        description: 'Stripe Subscription ID (cannot be changed)',
        editable: false,
      },
      {
        label: 'Subscription Start',
        value: patient.stripeCustomer?.subscriptionStart
          ? new Date(
              patient.stripeCustomer?.subscriptionStart
            ).toLocaleDateString()
          : '',
        description: 'Stripe Subscription Start (cannot be changed)',
        editable: false,
      },
      {
        label: 'Subscription End',
        value: patient.stripeCustomer?.subscriptionEnd
          ? new Date(
              patient.stripeCustomer?.subscriptionEnd
            ).toLocaleDateString()
          : '',
        description: 'Stripe Subscription End (cannot be changed)',
        editable: false,
      },
    ]
  }, [patient, medicalProviderOptions, dieticianOptions])

  const confirmCancelSubscription = () =>
    window.open(
      `https://dashboard.stripe.com/subscriptions/${patient.stripeCustomer?.subscriptionId}`,
      '_blank'
    )

  const openCareTimeline = () =>
    history.push(`${RoutePaths.DASHBOARD_PATIENTS}/${patient.id}/timeline`)
  const closePatientView = () => history.push(RoutePaths.DASHBOARD_PATIENTS)

  const generatePregnancySurveyLink = async () => {
    setPregnancySurveyLink('loading')
    const link = await fetchPregnancySurveyLink()
    setPregnancySurveyLink(link)
  }

  const handleUpdate = (label: string, value: string | boolean | Date) => {
    if (readOnly) {
      setTimeout(() => {
        setAlertText('You do not have permission to edit patients')
      }, 100)
      return
    }

    if (label === 'Medical Provider' || label === 'Dietician') {
      const type =
        label === 'Medical Provider' ? 'medical-provider' : 'dietician'
      const providers =
        label === 'Medical Provider' ? medicalProviders : dieticians
      const patientProvider = providers.find((p) => p.id === value)

      if (!patientProvider && value !== UNASSIGNED_VALUE) {
        setAlertText(
          `Unable to update. Could not find provider with id ${value}`
        )
        return
      }

      handleUpdatePatientProvider({
        patientId: patient.id,
        type,
        provider: patientProvider ?? null,
      })
    } else {
      const newPatient = produce(patient, (draftPatient: Patient) => {
        switch (typeof value) {
          case 'boolean':
            switch (label) {
              case 'SMS':
                draftPatient.smsPreference = {
                  sourceHealthId: draftPatient.sourceHealthId ?? '',
                  sms: value,
                }
                break
            }

            break

          case 'string':
            switch (label) {
              case 'First Name':
                draftPatient.firstName = value
                break
              case 'Last Name':
                draftPatient.lastName = value
                break
              case 'Preferred Name':
                draftPatient.preferredName = value
                break
              case 'Email':
                draftPatient.email = value
                break
              case 'Phone Number':
                draftPatient.phoneNumber = value
                break
              case 'State':
                draftPatient.waitlistUser.state.code = value
                break
            }

            break
          case 'object':
            switch (label) {
              case 'Date of Birth':
                draftPatient.waitlistUser.dob = value.toString()
                break
            }

            break
        }
      })

      if (newPatient) {
        const newPatientDto: PatientDto = {
          id: newPatient.id,
          firstName: newPatient.firstName,
          lastName: newPatient.lastName,
          preferredName: newPatient.preferredName,
          phoneNumber: newPatient.phoneNumber,
          smsPreference: newPatient.smsPreference,
          waitlistUser: newPatient.waitlistUser,
        }

        handleUpdatePatient(newPatientDto)
      }
    }
  }

  const isActive =
    patient.accountDeactivated === false &&
    patient.messagingDeactivated === false
  const statusClass = isActive ? 'status-active' : 'status-inactive'
  const statusBadgeClass = isActive
    ? 'status-badge-active'
    : 'status-badge-inactive'

  return (
    <div className="patient-view-container pt-4 pb-6">
      <div className="d-flex align-items-center justify-content-between mx-4">
        <div className="d-flex align-items-center">
          <Typography variant="h4" className="patient-name">
            {patient.firstName}{' '}
            {patient.preferredName ? `(${patient.preferredName})` : ''}{' '}
            {patient.lastName}
          </Typography>
          <div className="d-flex align-items-center button-margin">
            <div className={`status-badge ${statusBadgeClass}`} />
            <p className={`status mb-0 pb-0 ${statusClass}`}>
              <strong>
                {isActive ? 'Account Active' : 'Account Deactivated'}
              </strong>
            </p>
          </div>
        </div>
        <div className="d-flex">
          <Button variant="contained" onClick={openCareTimeline}>
            Open Care Timeline
          </Button>
          <Button
            variant="contained"
            className="cancel-button button-margin"
            onClick={closePatientView}
          >
            Close
          </Button>
        </div>
      </div>
      <div className="options-container">
        {options.map((option) => (
          <DataEditRow
            key={option.label}
            data={option}
            updateData={handleUpdate}
          />
        ))}
      </div>
      <div className="action-button-container d-flex flex-column">
        <div className="d-flex justify-content-center mt-3">
          <Button
            variant="contained"
            onClick={generatePregnancySurveyLink}
            disabled={pregnancySurveyLink === 'loading'}
          >
            Pregnancy Survey Link
          </Button>
        </div>
        {!readOnly && (
          <div className="d-flex justify-content-center mt-3">
            <Tooltip
              arrow
              title="Clicking this button will bring you to the Stripe Subscription page where you can then cancel the subscription."
            >
              <Button
                variant="contained"
                className="cancel-button"
                onClick={confirmCancelSubscription}
              >
                Cancel Subscription
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <PregnancySurveyDialog
        open={
          pregnancySurveyLink && pregnancySurveyLink !== 'loading'
            ? true
            : false
        }
        handleClose={() => setPregnancySurveyLink(undefined)}
        link={pregnancySurveyLink}
      />
    </div>
  )
}

export default memo(PatientView)

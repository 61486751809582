import { useAuth0 } from '@auth0/auth0-react'
import {
  ActivePatientsCSV as ActivePatientsCSVRoute,
  AppointmentsCSV as AppointmentsCSVRoute,
  AppointmentSurveyCSV as AppointmentSurveyCSVRoute,
  CancelledAppointmentsCSV,
  ConversionCSV as ConversionCSVRoute,
  InsuranceAppointmentsCSV as InsuranceAppointmentsCSVRoute,
  MessagingSLACSV as MessagingSLACSVRoute,
  MonthlyCheckinsCSV as MonthlyCheckinsCSVRoute,
  SourceAnalyticsCSV,
} from 'api/admin/routes'
import { getAxiosConfig } from 'utils/api'

interface UseDownloadCSVInterface {
  downloadConversionCSV: () => Promise<string>
  downloadAppointmentSurveyCSV: () => Promise<string>
  downloadMonthlyCheckinsCSV: () => Promise<string>
  downloadInsuranceAppointmentsCSV: () => Promise<string>
  downloadActivePatientsCSV: (startDate: Date, endDate: Date) => Promise<string>
  downloadMessagingSLACSV: (startDate: Date, endDate: Date) => Promise<string>
  downloadSourceAnalyticsCSV: (
    startDate: Date,
    endDate: Date
  ) => Promise<string>
  downloadNothingCSV: () => Promise<string>
  downloadAppointmentsCSV: (
    startDate: Date,
    endDate: Date,
    include?: string | boolean
  ) => Promise<string>
  downloadCancelledAppointmentsCSV: (
    startDate: Date,
    endDate: Date
  ) => Promise<string>
}

const useDownloadCSV = (): UseDownloadCSVInterface => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const downloadConversionCSV = async (): Promise<string> => {
    if (!isAuthenticated) {
      return ''
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${ConversionCSVRoute}`,
      getAxiosConfig(accessToken)
    ).then(async (res) => {
      const data = await res.text()
      return data
    })
  }

  const downloadAppointmentSurveyCSV = async (): Promise<string> => {
    if (!isAuthenticated) {
      return ''
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${AppointmentSurveyCSVRoute}`,
      getAxiosConfig(accessToken)
    ).then(async (res) => {
      const data = await res.text()
      return data
    })
  }

  const downloadMonthlyCheckinsCSV = async (): Promise<string> => {
    if (!isAuthenticated) {
      return ''
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${MonthlyCheckinsCSVRoute}`,
      getAxiosConfig(accessToken)
    ).then(async (res) => {
      const data = await res.text()
      return data
    })
  }

  const downloadInsuranceAppointmentsCSV = async (): Promise<string> => {
    if (!isAuthenticated) {
      return ''
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${InsuranceAppointmentsCSVRoute}`,
      getAxiosConfig(accessToken)
    ).then(async (res) => {
      const data = await res.text()

      if (!data) {
        throw 'No Appointments Found'
      }

      return data
    })
  }

  const downloadAppointmentsCSV = async (
    startDate: Date,
    endDate: Date,
    include?: string | boolean
  ): Promise<string> => {
    if (!isAuthenticated) {
      return ''
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${
        process.env.REACT_APP_SERVER_URL
      }${AppointmentsCSVRoute}?start=${startDate.getTime()}&end=${endDate.getTime()}${
        include ? `&include=${include}` : ''
      }`,
      getAxiosConfig(accessToken)
    ).then(async (res) => {
      const data = await res.text()

      if (!data) {
        throw 'No Appointments Found'
      }

      return data
    })
  }

  const downloadCancelledAppointmentsCSV = async (
    startDate: Date,
    endDate: Date
  ): Promise<string> => {
    if (!isAuthenticated) {
      return ''
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${
        process.env.REACT_APP_SERVER_URL
      }${CancelledAppointmentsCSV}?start=${startDate.getTime()}&end=${endDate.getTime()}`,
      getAxiosConfig(accessToken)
    ).then(async (res) => {
      const data = await res.text()

      if (!data) {
        throw 'No Appointments Found'
      }

      return data
    })
  }

  const downloadMessagingSLACSV = async (
    startDate: Date,
    endDate: Date
  ): Promise<string> => {
    if (!isAuthenticated) {
      return ''
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${
        process.env.REACT_APP_SERVER_URL
      }${MessagingSLACSVRoute}?start=${startDate.getTime()}&end=${endDate.getTime()}`,
      getAxiosConfig(accessToken)
    ).then(async (res) => {
      const data = await res.text()

      if (!data) {
        throw 'No Message Data Found'
      }

      return data
    })
  }

  const downloadActivePatientsCSV = async (
    startDate: Date,
    endDate: Date
  ): Promise<string> => {
    if (!isAuthenticated) {
      return ''
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${
        process.env.REACT_APP_SERVER_URL
      }${ActivePatientsCSVRoute}?start=${startDate.getTime()}&end=${endDate.getTime()}`,
      getAxiosConfig(accessToken)
    ).then(async (res) => {
      const data = await res.text()

      if (!data) {
        throw 'No data returned! Please try again'
      }

      return data
    })
  }

  const downloadSourceAnalyticsCSV = async (
    startDate: Date,
    endDate: Date
  ): Promise<string> => {
    if (!isAuthenticated) {
      return ''
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${
        process.env.REACT_APP_SERVER_URL
      }${SourceAnalyticsCSV}?start=${startDate.getTime()}&end=${endDate.getTime()}`,
      getAxiosConfig(accessToken)
    ).then(async (res) => {
      const data = await res.text()

      if (!data) {
        throw 'No data returned! Please try again'
      }

      return data
    })
  }

  const downloadNothingCSV = async (): Promise<string> => {
    if (!isAuthenticated) {
      return 'http://imgs.xkcd.com/comics/security.png'
    }

    return `"Welcome! You have found our secret joke book! Please feel free to add a new column for your rating, then send back to Ben so he can reupload",
    Joke,Ben's Rating,Tiffany's Rating
    "Don't use ""beef stew"" as a computer password. Its not stroganoff!",6,10
    Why did the PowerPoint presentation cross the road? To get to the other slide!,3,6
    How does a computer get drunk? It takes screenshots,6,7
    We'll we'll we'll.... if it isn't autocorrect.,10,9
    "My computer suddenly started belting out ""Someone Like You"". It's a Dell.",9,9
    Why did the computer show up late to work? It had a hard drive,8,7
    The guy who invented predictive text died last night. His funfair is next monkey.,10,10`
  }

  return {
    downloadConversionCSV,
    downloadAppointmentSurveyCSV,
    downloadMonthlyCheckinsCSV,
    downloadInsuranceAppointmentsCSV,
    downloadAppointmentsCSV,
    downloadActivePatientsCSV,
    downloadSourceAnalyticsCSV,
    downloadNothingCSV,
    downloadMessagingSLACSV,
    downloadCancelledAppointmentsCSV,
  }
}

export default useDownloadCSV

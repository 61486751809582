import {
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  Delete as DeleteIcon,
  SkipNext as SkipNextIcon,
  SkipPrevious as SkipPreviousIcon,
} from '@material-ui/icons'
import useTimeline from 'hooks/useTimeline'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Permissions } from 'containers/Core/utils'
import { useCallback, useMemo, useState } from 'react'
import { ModuleAction } from 'containers/Dashboard/types'
import ConfirmDialog from 'components/ConfirmDialog'

interface ModuleActionsTableProps {
  patientId: string
}

const ModuleActionsTable: React.FC<ModuleActionsTableProps> = ({
  patientId,
}) => {
  const { permissions } = useAuthContext()
  const {
    isLoading,
    moduleActions,
    handleDeleteAction,
    handleUpdateActionStatus,
  } = useTimeline(patientId)
  const [showComplete, setShowComplete] = useState<boolean>(false)
  const [showAdvanced, setShowAdvanced] = useState<boolean>(true)
  const [deleteAction, setDeleteAction] = useState<ModuleAction | null>(null)
  const [advanceAction, setAdvanceAction] = useState<{
    action: ModuleAction
    status: 'inactive' | 'active' | 'complete'
  } | null>(null)

  const closeConfirmDeleteDialog = () => setDeleteAction(null)
  const closeConfirmStatusDialog = () => setAdvanceAction(null)
  const toggleShowComplete = () => setShowComplete((current) => !current)
  const toggleShowAdvanced = () => setShowAdvanced((current) => !current)

  const handleDelete = useCallback(() => {
    if (deleteAction) {
      handleDeleteAction({ type: 'module', id: deleteAction.id })
    }
  }, [deleteAction])

  const handleUpdateStatus = useCallback(() => {
    if (advanceAction) {
      handleUpdateActionStatus({
        status: advanceAction.status,
        id: advanceAction.action.id,
        type: 'module',
      })
    }
  }, [advanceAction])

  const orderedModuleActions = useMemo(() => {
    const completedActions = []
    const activeActions = []
    const inactiveActions = []

    for (const moduleAction of moduleActions) {
      switch (moduleAction.status) {
        case 'complete':
          if (showComplete) {
            completedActions.push(moduleAction)
          }

          break
        case 'active':
          activeActions.push(moduleAction)
          break
        case 'inactive':
          inactiveActions.push(moduleAction)
          break
      }
    }

    return [...completedActions, ...activeActions, ...inactiveActions]
  }, [moduleActions, showComplete])

  return (
    <>
      <TableContainer className="table-container">
        <div className="d-flex justify-content-between">
          <Typography variant="h5">Module Actions</Typography>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={showAdvanced}
                  onChange={toggleShowAdvanced}
                  name="show-details"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />
              }
              label="Show Conditions"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showComplete}
                  onChange={toggleShowComplete}
                  name="show-complete"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Show Completed"
              labelPlacement="start"
            />
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Module ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created</TableCell>
              {showAdvanced && (
                <>
                  <TableCell>Applicability Condition</TableCell>
                  <TableCell>Offset Date</TableCell>
                  <TableCell>Offset Delay</TableCell>
                </>
              )}
              {showComplete && <TableCell>Completed</TableCell>}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedModuleActions.map((moduleAction) => {
              const created = new Date(moduleAction.created)
              const completed = moduleAction.complete
                ? new Date(moduleAction.complete)
                : undefined

              return (
                <TableRow key={moduleAction.id}>
                  <TableCell>{moduleAction.moduleId}</TableCell>
                  <TableCell>{moduleAction.status}</TableCell>
                  <TableCell>
                    {created.toLocaleDateString()}{' '}
                    {created.toLocaleTimeString()}
                  </TableCell>
                  {showAdvanced && (
                    <>
                      <TableCell>
                        {moduleAction.applicabilityCondition?.code ?? ''}
                      </TableCell>
                      <TableCell>
                        {moduleAction.offsetDate
                          ? new Date(
                              moduleAction.offsetDate
                            ).toLocaleDateString()
                          : ''}
                      </TableCell>
                      <TableCell>{moduleAction.offsetDelay ?? ''}</TableCell>
                    </>
                  )}
                  {showComplete && (
                    <TableCell>
                      {completed
                        ? `${completed.toLocaleDateString()} ${completed.toLocaleTimeString()}`
                        : ''}
                    </TableCell>
                  )}
                  <TableCell align="right">
                    {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                      moduleAction.status !== 'complete' && (
                        <IconButton
                          className="list-button"
                          size="small"
                          onClick={() =>
                            setAdvanceAction({
                              action: moduleAction,
                              status: 'inactive',
                            })
                          }
                          disabled={moduleAction.status === 'inactive'}
                        >
                          <SkipPreviousIcon />
                        </IconButton>
                      )}
                    {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                      moduleAction.status !== 'complete' && (
                        <IconButton
                          className="list-button"
                          size="small"
                          onClick={() =>
                            setAdvanceAction({
                              action: moduleAction,
                              status:
                                moduleAction.status === 'active'
                                  ? 'complete'
                                  : 'active',
                            })
                          }
                        >
                          <SkipNextIcon />
                        </IconButton>
                      )}
                    {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                      moduleAction.status !== 'complete' && (
                        <IconButton
                          className="list-button"
                          size="small"
                          onClick={() => setDeleteAction(moduleAction)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {!isLoading && moduleActions.length === 0 && (
          <div className="text-center">No module actions</div>
        )}
        <ConfirmDialog
          key={deleteAction?.id ?? 'no-delete-action'}
          open={deleteAction !== null}
          text={`Are you sure you want to delete ${deleteAction?.definition} (Status: ${deleteAction?.status})`}
          titleText="Confirm Delete Module Action"
          helperText="Note: Some actions are related (i.e. book-followup-md and join-followup-md. Deleting just one can cause issues. Proceed with caution."
          onConfirm={handleDelete}
          handleClose={closeConfirmDeleteDialog}
        />
        <ConfirmDialog
          key={advanceAction?.action.id ?? 'advance-action'}
          open={advanceAction !== null}
          text={`Are you sure you want to update the status of ${advanceAction?.action.definition} from ${advanceAction?.action.status} to ${advanceAction?.status}?`}
          titleText="Confirm Update Module Action Status"
          helperText={
            advanceAction?.status === 'complete'
              ? 'Note: This action cannot be undone'
              : undefined
          }
          onConfirm={handleUpdateStatus}
          handleClose={closeConfirmStatusDialog}
        />
      </TableContainer>
    </>
  )
}

export default ModuleActionsTable

import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'

import Login from 'containers/Login'
import Logout from 'containers/Logout'
import ProtectedRoute from 'components/ProtectedRoute'
import PageNotFound from 'containers/PageNotFound'
import DashboardLayout from 'containers/Dashboard/DashboardLayout'

export const RoutePaths = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  DASHBOARD: '/dashboard',
  DASHBOARD_PATIENTS: '/dashboard/patients',
  DASHBOARD_ANALYTICS: '/dashboard/analytics',
  DASHBOARD_PROVIDERS: '/dashboard/providers',
  DASHBOARD_ELIGIBLE_INSURANCES: '/dashboard/eligible-insurances',
}

export const ExternalRoutes = {
  STRIPE: 'https://dashboard.stripe.com/dashboard',
  ELATION: 'https://app.elationemr.com/patients/',
  ACTIVE_CAMPAIGN: 'https://allarahealth.activehosted.com/overview/',
  CATALYST: 'https://console.withcatalyst.com',
  COMMUNITY: 'https://community.allarahealth.com/',
  UNBOUNCE: 'https://app.unbounce.com/4480321/pages',
  NOTION: 'https://www.notion.so/Allara-wiki-3caeda9b11b14dfe98b8527535c0f03c',
}

export const DeveloperRoutes = {
  VERCEL: 'https://vercel.com/allara',
  GITHUB: 'https://github.com/Allara-Health',
  HEROKU: 'https://dashboard.heroku.com/apps/allara-patient-app',
  APTIBLE: 'https://dashboard.aptible.com/accounts/4428/apps',
  CATALYST_DOCS: 'https://docs.withcatalyst.com/reference/introduction',
  STRIPE_DOCS: 'https://stripe.com/docs/api',
  ELATION_DOCS: 'https://docs.elationhealth.com/reference',
  ACTIVE_CAMPAIGN_DOCS: 'https://developers.activecampaign.com/reference',
}

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path={RoutePaths.LOGIN}>
        <Login />
      </Route>
      <Route path={RoutePaths.LOGOUT}>
        <Logout />
      </Route>
      <ProtectedRoute component={DashboardLayout} path={RoutePaths.DASHBOARD} />
      <ProtectedRoute exact path="/">
        <Redirect to={RoutePaths.DASHBOARD} />
      </ProtectedRoute>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  )
}

export default Routes

import { Providers as ProvidersRoute } from 'api/admin/routes'
import { Provider, ProviderDto } from 'containers/Dashboard/types'
import { useMemo } from 'react'
import {
  useMutation,
  UseMutateFunction,
  useQuery,
  useQueryClient,
} from 'react-query'
import axios, { AxiosResponse } from 'axios'
import { getAxiosConfig, QUERY_KEYS } from 'utils/api'
import { useAlertContext } from 'context/AlertContext'
import { useAuth0 } from '@auth0/auth0-react'

interface UseProvidersInterface {
  isLoading: boolean
  error: unknown
  providers: Provider[]
  medicalProviders: Provider[]
  dieticians: Provider[]
  handleAddProvider: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    ProviderDto,
    unknown
  >
  handleUpdateProvider: UseMutateFunction<
    AxiosResponse<string> | null,
    unknown,
    Provider,
    unknown
  >
}

const useProviders = (): UseProvidersInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const fetchProviders = async (): Promise<Provider[]> => {
    if (!isAuthenticated) {
      return []
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${ProvidersRoute}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          setAlertText(`Error loading providers (${res.status})`)
          return []
        }
      })
      .catch(() => {
        setAlertText('Error loading providers.')
        return []
      })
  }

  const { isLoading, error, data } = useQuery(
    QUERY_KEYS.PROVIDERS,
    fetchProviders,
    { staleTime: Infinity }
  )

  const providers = useMemo(() => data ?? [], [data])

  const updateProvider = async (
    provider: Provider
  ): Promise<AxiosResponse<string> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}${ProvidersRoute}/${provider.id}`,
        provider,
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to update providers!')
        } else {
          setAlertText(err.message)
        }

        return null
      })
  }

  const mutateUpdate = useMutation(updateProvider, {
    onSettled: () => queryClient.invalidateQueries(QUERY_KEYS.PROVIDERS),
    onMutate: async (provider: Provider) => {
      await queryClient.cancelQueries(QUERY_KEYS.PROVIDERS)

      const previousData = queryClient.getQueryData<Provider[]>(
        QUERY_KEYS.PROVIDERS
      )

      if (previousData) {
        queryClient.setQueryData<Provider[]>(
          QUERY_KEYS.PROVIDERS,
          previousData.map((prov) => {
            if (prov.id === provider.id) {
              return provider
            }

            return prov
          })
        )
      }

      return { previousData }
    },
  })

  const handleUpdateProvider = mutateUpdate.mutate

  const addProvider = async (
    provider: ProviderDto
  ): Promise<AxiosResponse<string> | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}${ProvidersRoute}`,
        provider,
        getAxiosConfig(accessToken)
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setAlertText('Unauthorized to add providers!')
        } else {
          setAlertText(err.response.data.message)
        }

        return null
      })
  }

  const mutateAdd = useMutation(addProvider, {
    onSettled: () => queryClient.invalidateQueries(QUERY_KEYS.PROVIDERS),
    onMutate: async (provider: ProviderDto) => {
      await queryClient.cancelQueries(QUERY_KEYS.PROVIDERS)

      const previousData = queryClient.getQueryData<Provider[]>(
        QUERY_KEYS.PROVIDERS
      )

      if (previousData) {
        previousData.push({
          ...provider,
          id: String(Date.now()),
          sourceHealthId: String(Date.now()),
        })
        queryClient.setQueryData(QUERY_KEYS.PROVIDERS, previousData)
      }

      return { previousData }
    },
  })

  const handleAddProvider = mutateAdd.mutate

  const medicalProviders = useMemo(
    () => providers.filter((p) => p.type === 'Medical Provider'),
    [providers]
  )

  const dieticians = useMemo(
    () => providers.filter((p) => p.type === 'Dietician'),
    [providers]
  )

  return {
    isLoading,
    error,
    medicalProviders,
    providers,
    dieticians,
    handleAddProvider,
    handleUpdateProvider,
  }
}

export default useProviders

import {
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  Delete as DeleteIcon,
  SkipNext as SkipNextIcon,
  SkipPrevious as SkipPreviousIcon,
} from '@material-ui/icons'
import useTimeline from 'hooks/useTimeline'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Permissions } from 'containers/Core/utils'
import { useCallback, useMemo, useState } from 'react'
import { SystemAction } from 'containers/Dashboard/types'
import ConfirmDialog from 'components/ConfirmDialog'

interface SystemActionsTableProps {
  patientId: string
}

const SystemActionsTable: React.FC<SystemActionsTableProps> = ({
  patientId,
}) => {
  const { permissions } = useAuthContext()
  const {
    isLoading,
    systemActions,
    handleDeleteAction,
    handleUpdateActionStatus,
  } = useTimeline(patientId)
  const [showComplete, setShowComplete] = useState<boolean>(false)
  const [showAdvanced, setShowAdvanced] = useState<boolean>(true)
  const [deleteAction, setDeleteAction] = useState<SystemAction | null>(null)
  const [advanceAction, setAdvanceAction] = useState<{
    action: SystemAction
    status: 'inactive' | 'active' | 'complete'
  } | null>(null)

  const closeConfirmDeleteDialog = () => setDeleteAction(null)
  const closeConfirmStatusDialog = () => setAdvanceAction(null)
  const toggleShowComplete = () => setShowComplete((current) => !current)
  const toggleShowAdvanced = () => setShowAdvanced((current) => !current)

  const handleDelete = useCallback(() => {
    if (deleteAction) {
      handleDeleteAction({ type: 'system', id: deleteAction.id })
    }
  }, [deleteAction])

  const handleUpdateStatus = useCallback(() => {
    if (advanceAction) {
      handleUpdateActionStatus({
        status: advanceAction.status,
        id: advanceAction.action.id,
        type: 'system',
      })
    }
  }, [advanceAction])

  const orderedSystemActions = useMemo(() => {
    const completedActions = []
    const activeActions = []
    const inactiveActions = []

    for (const systemAction of systemActions) {
      switch (systemAction.status) {
        case 'complete':
          if (showComplete) {
            completedActions.push(systemAction)
          }

          break
        case 'active':
          activeActions.push(systemAction)
          break
        case 'inactive':
          inactiveActions.push(systemAction)
          break
      }
    }

    return [...completedActions, ...activeActions, ...inactiveActions]
  }, [systemActions, showComplete])

  return (
    <>
      <TableContainer className="table-container">
        <div className="d-flex justify-content-between">
          <Typography variant="h5">System Actions</Typography>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={showAdvanced}
                  onChange={toggleShowAdvanced}
                  name="show-details"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />
              }
              label="Show Conditions"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showComplete}
                  onChange={toggleShowComplete}
                  name="show-complete"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Show Completed"
              labelPlacement="start"
            />
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Definition</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created</TableCell>
              {showAdvanced && (
                <>
                  <TableCell>Applicability Condition</TableCell>
                  <TableCell>Offset Date</TableCell>
                  <TableCell>Offset Delay</TableCell>
                </>
              )}
              {showComplete && <TableCell>Completed</TableCell>}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedSystemActions.map((systemAction) => {
              const created = new Date(systemAction.created)
              const completed = systemAction.complete
                ? new Date(systemAction.complete)
                : undefined

              return (
                <TableRow key={systemAction.id}>
                  <TableCell>{systemAction.definition}</TableCell>
                  <TableCell>{systemAction.status}</TableCell>
                  <TableCell>
                    {created.toLocaleDateString()}{' '}
                    {created.toLocaleTimeString()}
                  </TableCell>
                  {showAdvanced && (
                    <>
                      <TableCell>
                        {systemAction.applicabilityCondition?.code ?? ''}
                      </TableCell>
                      <TableCell>
                        {systemAction.offsetDate
                          ? new Date(
                              systemAction.offsetDate
                            ).toLocaleDateString()
                          : ''}
                      </TableCell>
                      <TableCell>{systemAction.offsetDelay ?? ''}</TableCell>
                    </>
                  )}
                  {showComplete && (
                    <TableCell>
                      {completed
                        ? `${completed.toLocaleDateString()} ${completed.toLocaleTimeString()}`
                        : ''}
                    </TableCell>
                  )}
                  <TableCell align="right">
                    {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                      systemAction.status !== 'complete' && (
                        <IconButton
                          className="list-button"
                          size="small"
                          onClick={() =>
                            setAdvanceAction({
                              action: systemAction,
                              status: 'inactive',
                            })
                          }
                          disabled={systemAction.status === 'inactive'}
                        >
                          <SkipPreviousIcon />
                        </IconButton>
                      )}
                    {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                      systemAction.status !== 'complete' && (
                        <IconButton
                          className="list-button"
                          size="small"
                          onClick={() =>
                            setAdvanceAction({
                              action: systemAction,
                              status:
                                systemAction.status === 'active'
                                  ? 'complete'
                                  : 'active',
                            })
                          }
                        >
                          <SkipNextIcon />
                        </IconButton>
                      )}
                    {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                      systemAction.status !== 'complete' && (
                        <IconButton
                          className="list-button"
                          size="small"
                          onClick={() => setDeleteAction(systemAction)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {!isLoading && systemActions.length === 0 && (
          <div className="text-center">No system actions</div>
        )}
        <ConfirmDialog
          key={deleteAction?.id ?? 'no-delete-action'}
          open={deleteAction !== null}
          text={`Are you sure you want to delete ${deleteAction?.definition} (Status: ${deleteAction?.status})`}
          titleText="Confirm Delete System Action"
          helperText="Note: Some actions are related (i.e. book-followup-md and join-followup-md. Deleting just one can cause issues. Proceed with caution."
          onConfirm={handleDelete}
          handleClose={closeConfirmDeleteDialog}
        />
        <ConfirmDialog
          key={advanceAction?.action.id ?? 'advance-action'}
          open={advanceAction !== null}
          text={`Are you sure you want to update the status of ${advanceAction?.action.definition} from ${advanceAction?.action.status} to ${advanceAction?.status}?`}
          titleText="Confirm Update System Action Status"
          helperText={
            advanceAction?.status === 'complete'
              ? 'Note: This action cannot be undone'
              : undefined
          }
          onConfirm={handleUpdateStatus}
          handleClose={closeConfirmStatusDialog}
        />
      </TableContainer>
    </>
  )
}

export default SystemActionsTable

import { memo } from 'react'
import { Bar, Pie } from 'react-chartjs-2'
import { CardContent, Typography } from '@mui/material'
import { ChartType } from './types'
import { Funnel as FunnelReact2 } from 'funnel-react-2'
import { simpleHash } from 'utils/hash'

interface AnalyticsChartCardProps {
  title: string
  type: ChartType
  description?: string
  chartData: {
    label: string
    data: ChartDataProps[]
  }
}

interface ChartDataProps {
  label: string
  value: number
}

const AnalyticsChartCard: React.FC<AnalyticsChartCardProps> = ({
  title,
  type,
  description,
  chartData,
}) => {
  const getBackgroundColor = () => {
    const backgroundColors = [
      '#5988ff',
      '#f6f5ef',
      '#065063',
      '#f4f6fa',
      '#f0d732',
    ]

    if (type === 'bar') {
      return backgroundColors[0]
    } else if (type === 'pie') {
      return backgroundColors.slice(0, 5)
    }
  }

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }

  const data = {
    labels: chartData.data.map((d) => d.label),
    datasets: [
      {
        label: chartData.label,
        data: chartData.data.map((d) => d.value),
        backgroundColor: getBackgroundColor(),
        borderWidth: 0,
      },
    ],
  }

  return (
    <div>
      <CardContent>
        <Typography variant="h6" className="analytics-card-title">
          {title}
        </Typography>
        {description && (
          <Typography variant="subtitle2">{description}</Typography>
        )}
        {type === 'bar' && <Bar data={data} options={options} />}
        {type === 'pie' && <Pie data={data} />}
        {type === 'funnel' && <Funnel data={chartData.data} />}
      </CardContent>
    </div>
  )
}

const Funnel: React.FC<{ data: ChartDataProps[] }> = ({ data }) => {
  return (
    <FunnelReact2
      key={simpleHash(JSON.stringify(data))}
      height={252}
      colors={{
        graph: ['#BAE7FF', '#1890FF'],
      }}
      labelKey="label"
      valueKey="value"
      width={800}
      responsive={true}
      displayPercent={true}
      renderLabel={(_: number, value: string) => {
        return <span style={{ fontSize: 20 }}>{value}</span>
      }}
      renderValue={(_: number, value: string | number) => {
        return <span>{value} users</span>
      }}
      renderDropOffPercentage={(index: number, value: number) => {
        return (
          <span style={{ color: 'rgb(255, 42, 42)' }}>
            {index !== 0 ? `${value !== 0 ? '-' : ''}${value}%` : ''}
          </span>
        )
      }}
      data={data}
    />
  )
}

export default memo(AnalyticsChartCard)

import {
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  Delete as DeleteIcon,
  SkipNext as SkipNextIcon,
  SkipPrevious as SkipPreviousIcon,
} from '@material-ui/icons'
import useTimeline from 'hooks/useTimeline'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Permissions } from 'containers/Core/utils'
import { Patient, ProviderAction } from 'containers/Dashboard/types'
import ConfirmDialog from 'components/ConfirmDialog'
import { useCallback, useMemo, useState } from 'react'

interface ProviderActionsTableProps {
  patient: Patient
}

const ProviderActionsTable: React.FC<ProviderActionsTableProps> = ({
  patient,
}) => {
  const { permissions } = useAuthContext()
  const {
    isLoading,
    providerActions,
    handleDeleteAction,
    handleUpdateActionStatus,
  } = useTimeline(patient.id)
  const [showComplete, setShowComplete] = useState<boolean>(false)
  const [showAdvanced, setShowAdvanced] = useState<boolean>(true)
  const [deleteAction, setDeleteAction] = useState<ProviderAction | null>(null)
  const [advanceAction, setAdvanceAction] = useState<{
    action: ProviderAction
    status: 'inactive' | 'active' | 'complete'
  } | null>(null)

  const closeConfirmDeleteDialog = () => setDeleteAction(null)
  const closeConfirmStatusDialog = () => setAdvanceAction(null)
  const toggleShowComplete = () => setShowComplete((current) => !current)
  const toggleShowAdvanced = () => setShowAdvanced((current) => !current)

  const handleDelete = useCallback(() => {
    if (deleteAction) {
      handleDeleteAction({ type: 'provider', id: deleteAction.id })
    }
  }, [deleteAction])

  const handleUpdateStatus = useCallback(() => {
    if (advanceAction) {
      handleUpdateActionStatus({
        status: advanceAction.status,
        id: advanceAction.action.id,
        type: 'provider',
      })
    }
  }, [advanceAction])

  const orderedProviderActions = useMemo(() => {
    const completedActions = []
    const activeActions = []
    const inactiveActions = []

    for (const providerAction of providerActions) {
      switch (providerAction.status) {
        case 'complete':
          if (showComplete) {
            completedActions.push(providerAction)
          }

          break
        case 'active':
          activeActions.push(providerAction)
          break
        case 'inactive':
          inactiveActions.push(providerAction)
          break
      }
    }

    return [...completedActions, ...activeActions, ...inactiveActions]
  }, [providerActions, showComplete])

  return (
    <TableContainer className="table-container">
      <div className="d-flex justify-content-between">
        <Typography variant="h5">Provider Actions</Typography>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={showAdvanced}
                onChange={toggleShowAdvanced}
                name="show-details"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                color="primary"
              />
            }
            label="Show Conditions"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showComplete}
                onChange={toggleShowComplete}
                name="show-complete"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Show Completed"
            labelPlacement="start"
          />
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Definition</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created</TableCell>
            {showAdvanced && (
              <>
                <TableCell>Applicability Condition</TableCell>
                <TableCell>Complete Condition</TableCell>
              </>
            )}
            {showComplete && <TableCell>Completed</TableCell>}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderedProviderActions.map((providerAction) => {
            const created = new Date(providerAction.created)
            const completed = providerAction.complete
              ? new Date(providerAction.complete)
              : undefined
            return (
              <TableRow key={providerAction.id}>
                <TableCell>{providerAction.definition}</TableCell>
                <TableCell>
                  {providerAction.provider
                    ? `${providerAction.provider.firstName} ${providerAction.provider.lastName}`
                    : 'ERROR (NULL)'}
                </TableCell>
                <TableCell>{providerAction.status}</TableCell>
                <TableCell>
                  {created.toLocaleDateString()} {created.toLocaleTimeString()}
                </TableCell>
                {showAdvanced && (
                  <>
                    <TableCell>
                      {providerAction.applicabilityCondition?.code ?? ''}
                    </TableCell>
                    <TableCell>
                      {providerAction.completeCondition?.code ?? ''}
                    </TableCell>
                  </>
                )}
                {showComplete && (
                  <TableCell>
                    {completed
                      ? `${completed.toLocaleDateString()} ${completed.toLocaleTimeString()}`
                      : ''}
                  </TableCell>
                )}
                <TableCell align="right">
                  {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                    providerAction.status !== 'complete' && (
                      <IconButton
                        className="list-button"
                        size="small"
                        onClick={() =>
                          setAdvanceAction({
                            action: providerAction,
                            status: 'inactive',
                          })
                        }
                        disabled={providerAction.status === 'inactive'}
                      >
                        <SkipPreviousIcon />
                      </IconButton>
                    )}
                  {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                    providerAction.status !== 'complete' && (
                      <IconButton
                        className="list-button"
                        size="small"
                        onClick={() =>
                          setAdvanceAction({
                            action: providerAction,
                            status:
                              providerAction.status === 'active'
                                ? 'complete'
                                : 'active',
                          })
                        }
                      >
                        <SkipNextIcon />
                      </IconButton>
                    )}
                  {permissions.includes(Permissions.UPDATE_PATIENTS) &&
                    providerAction.status !== 'complete' && (
                      <IconButton
                        className="list-button"
                        size="small"
                        onClick={() => setDeleteAction(providerAction)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {!isLoading && providerActions.length === 0 && (
        <div className="text-center">No provider actions</div>
      )}
      <ConfirmDialog
        key={deleteAction?.id ?? 'no-delete-action'}
        open={deleteAction !== null}
        text={`Are you sure you want to delete ${deleteAction?.definition} (Status: ${deleteAction?.status})`}
        titleText="Confirm Delete Provider Action"
        helperText="Note: Some actions are related (i.e. book-followup-md and join-followup-md. Deleting just one can cause issues. Proceed with caution."
        onConfirm={handleDelete}
        handleClose={closeConfirmDeleteDialog}
      />
      <ConfirmDialog
        key={advanceAction?.action.id ?? 'advance-action'}
        open={advanceAction !== null}
        text={`Are you sure you want to update the status of ${advanceAction?.action.definition} from ${advanceAction?.action.status} to ${advanceAction?.status}?`}
        titleText="Confirm Update Provider Action Status"
        helperText={
          advanceAction?.status === 'complete'
            ? 'Note: This action cannot be undone'
            : undefined
        }
        onConfirm={handleUpdateStatus}
        handleClose={closeConfirmStatusDialog}
      />
    </TableContainer>
  )
}

export default ProviderActionsTable

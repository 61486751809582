import { createContext, memo, ReactNode, useCallback, useState } from 'react'
import AlertDialog from './AlertDialog'

export interface InfoAlertContextInterface {
  setInfoAlertText: (value: string) => void
}

export const InfoAlertContext = createContext<InfoAlertContextInterface>(
  {} as InfoAlertContextInterface
)

interface InfoAlertProviderProps {
  children: ReactNode
}

export const InfoAlertProvider: React.FC<InfoAlertProviderProps> = memo(
  ({ children }) => {
    const [alertText, setInfoAlertText] = useState<string>('')

    const handleCloseAlert = useCallback(() => {
      setInfoAlertText('')
    }, [setInfoAlertText])

    return (
      <InfoAlertContext.Provider value={{ setInfoAlertText }}>
        <AlertDialog
          open={alertText !== ''}
          message={alertText}
          close={handleCloseAlert}
        />
        {children}
      </InfoAlertContext.Provider>
    )
  }
)

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@material-ui/icons'
import { memo, useState } from 'react'

interface PregnancySurveyDialogProps {
  open: boolean
  link?: string
  handleClose: () => void
}

const PregnancySurveyDialog: React.FC<PregnancySurveyDialogProps> = ({
  open,
  link,
  handleClose,
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const handleCancel = () => {
    handleClose()
  }

  const handleCopy = () => {
    if (link) {
      setIsCopied(true)
      navigator.clipboard.writeText(link)
      setTimeout(() => setIsCopied(false), 5000)
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="pregnancy-survey-link"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">Copy Pregnancy Survey Link</Typography>
        <p>This link is valid for 30 days</p>
        <div className="d-flex align-items-start">
          <pre className="border rounded p-3 bg-light text-muted flex-grow-1 m-0">
            <code>{link}</code>
          </pre>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel}>
          Close
        </Button>
        <Button variant="contained" onClick={handleCopy}>
          {isCopied ? 'Copied!' : 'Copy'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(PregnancySurveyDialog)

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { memo, useEffect, useState } from 'react'
import { Add as AddIcon } from '@material-ui/icons'
import { useHistory, useParams } from 'react-router'
import { RoutePaths } from 'containers/Core/Routes'
import useEligibleInsurances, { Insurance } from 'hooks/useEligibleInsurance'
import EligibleInsuranceTableRow from './EligibleInsuranceTableRow'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Permissions } from 'containers/Core/utils'
import NewEligibleInsuranceDialog from './NewEligibleInsuranceDialog'

interface EligibleInsuranceParams {
  provider?: string
}

const EligibleInsuranceList: React.FC = () => {
  const history = useHistory()
  const { permissions } = useAuthContext()
  const { insurances } = useEligibleInsurances()
  const { provider } = useParams<EligibleInsuranceParams>()
  const [editInsurance, setEditInsurance] = useState<Insurance | undefined>(
    undefined
  )

  const openAddNewDialog = () =>
    history.push(`${RoutePaths.DASHBOARD_ELIGIBLE_INSURANCES}/new`)

  const closeDialog = () => {
    history.push(RoutePaths.DASHBOARD_ELIGIBLE_INSURANCES)
    setEditInsurance(undefined)
  }

  useEffect(() => {
    if (Object.keys(insurances).length && provider && provider !== 'new') {
      const insurance = insurances[provider]

      if (insurance) {
        setEditInsurance(insurance)
      }
    }
  }, [insurances, provider])

  return (
    <div>
      <TableContainer className="table-container">
        <div className="d-flex justify-content-end">
          {permissions.includes(Permissions.ADD_ELIGIBLE_INSURANCES) && (
            <Button
              variant="contained"
              className="add-new-button"
              startIcon={<AddIcon />}
              onClick={openAddNewDialog}
            >
              Add New
            </Button>
          )}
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>States</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(insurances).map((insurance) => (
              <EligibleInsuranceTableRow
                key={insurance.provider}
                insurance={insurance}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NewEligibleInsuranceDialog
        open={provider !== undefined}
        handleClose={closeDialog}
        insurance={editInsurance}
      />
    </div>
  )
}

export default memo(EligibleInsuranceList)

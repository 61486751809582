import { memo, useCallback, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Permissions } from 'containers/Core/utils'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Button } from '@mui/material'
import { GetApp as DownloadIcon } from '@material-ui/icons'
import { handleDownloadCSV } from 'utils/csv'
import { useSearchContext } from 'context/SearchContext/SearchProvider'
import usePatients from 'hooks/usePatients'

const PatientSearch: React.FC = () => {
  const { permissions } = useAuthContext()
  const { resetSearchText } = useSearchContext()
  const { downloadPatientCSV } = usePatients()

  const handleDownloadCsv = useCallback(async () => {
    const csvString = await downloadPatientCSV()

    if (csvString) {
      handleDownloadCSV(csvString, `Allara_Patients`)
    }
  }, [])

  // Ensure when this component mounts that the search is cleared
  useEffect(() => resetSearchText(), [])

  return (
    <Container className="d-flex flex-column align-items-center height-full">
      <p className="h3 text-center mt-6 mb-3">
        Select a patient using the search bar at the top of the page, or use a
        quick action below.
      </p>
      {permissions.includes(Permissions.DOWNLOAD_PATIENTS) && (
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={handleDownloadCsv}
        >
          Download Patient CSV
        </Button>
      )}
    </Container>
  )
}

export default memo(PatientSearch)

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import { Close as CloseIcon } from '@material-ui/icons'
import { WaitlistUser } from 'api/admin/routes'
import Loader from 'components/Loader'
import StateSelect from 'components/StateSelect'

import { useCallback, useState } from 'react'
import { getAxiosConfig } from 'utils/api'
import { Search as SearchIcon } from '@material-ui/icons'
import { WaitlistUserDto } from 'containers/Dashboard/types'

import './styles.scss'
import useWaitlistUsers from 'hooks/useWaitlistUsers'
import { useAlertContext } from 'context/AlertContext'
import { useInfoAlertContext } from 'context/InfoAlertContext'

interface WaitlistUserStateDialogProps {
  open: boolean
  handleClose: () => void
}

const WaitlistUserStateDialog: React.FC<WaitlistUserStateDialogProps> = ({
  open,
  handleClose,
}) => {
  const { updateWaitlistUser } = useWaitlistUsers()
  const { setAlertText } = useAlertContext()
  const { setInfoAlertText } = useInfoAlertContext()

  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)

  const [emailText, setEmailText] = useState<string>('')
  const [waitlistUser, setWaitlistUser] = useState<WaitlistUserDto | null>(null)
  const [selectedState, setSelectedState] = useState<string>('')

  const handleUpdateSelectedState = (event: SelectChangeEvent) =>
    setSelectedState(event.target.value as string)

  const handleSubmit = async () => {
    if (!isAuthenticated) {
      return
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${WaitlistUser}/${emailText}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json()
          setWaitlistUser(data)
        }
      })
      .catch(() => {
        setAlertText('Could not find user with the email provided')

        return
      })
      .finally(() => setIsLoading(false))
  }

  const handleEmailText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setEmailText(event.target.value),
    []
  )
  const handleUpdateState = useCallback(async () => {
    try {
      setUpdating(true)
      const data: WaitlistUserDto = {
        firstName: waitlistUser?.firstName,
        email: waitlistUser?.email,
        lastName: waitlistUser?.lastName,
        state: {
          code: selectedState,
        },
      }
      const response = await updateWaitlistUser(data)

      if (response?.data) {
        setInfoAlertText('Waitlist User State Update Successfully')
        handleClose()
      } else {
        setAlertText('An Error Occured, try again')
      }

      setUpdating(false)
    } catch (error) {
      setAlertText('An Error Occurred, try again')
      setUpdating(false)
    }
  }, [selectedState])

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="waitlist-user-state-dialog-title"
      fullWidth
      maxWidth="md"
      className="waitlist-user-state-dialog"
    >
      <DialogTitle id="waitlist-user-state-dialog-title">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" className="title">
          Change Waitlist User State
        </Typography>
        <Typography variant="h6">
          Provide email of Waitlist User you wish to change state for
        </Typography>
        <form className="mt-3 d-flex w-100 justify-content-between">
          <TextField
            value={emailText}
            placeholder="Type email..."
            onChange={handleEmailText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize={'large'} />
                </InputAdornment>
              ),
              classes: { root: 'search' },
            }}
            variant="standard"
            fullWidth
          />
          <div className="w-100 d-flex justify-content-end">
            <Button
              variant="contained"
              className="submit-button"
              size="large"
              disabled={!emailText}
              onClick={handleSubmit}
            >
              {isLoading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                'Find Waitlist User'
              )}
            </Button>
          </div>
        </form>
        {waitlistUser && (
          <div className="mt-5 mb-5">
            <Grid container spacing={3}>
              <Grid item sm={3}>
                <span className="fw-bold">
                  First Name: {waitlistUser?.firstName}
                </span>
              </Grid>
              <Grid item sm={3}>
                <span className="fw-bold">
                  Last Name: {waitlistUser?.lastName}
                </span>
              </Grid>
              <Grid item sm={3}>
                <span className="fw-bold">Email: {waitlistUser?.email}</span>
              </Grid>
              <Grid item sm={3}>
                <span className="fw-bold">
                  State: {waitlistUser?.state.code}
                </span>
              </Grid>
            </Grid>
          </div>
        )}
        <form className="mt-2 d-flex align-content-center w-50 justify-content-between mb-2 w-100">
          <FormControl required className="select-form-control w-100">
            <InputLabel id="state-label">State</InputLabel>
            <StateSelect
              label="State"
              labelId="state-select"
              value={selectedState}
              onChange={handleUpdateSelectedState}
            />
          </FormControl>

          <div className="d-flex mt-4 w-100 justify-content-end">
            <Button
              variant="contained"
              className="submit-button"
              size="large"
              disabled={!selectedState}
              onClick={handleUpdateState}
            >
              {updating ? (
                <div>
                  <Loader />
                </div>
              ) : (
                ' Update State'
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default WaitlistUserStateDialog

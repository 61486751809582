import { Chip, IconButton, TableCell, TableRow } from '@mui/material'
import { Insurance } from 'hooks/useEligibleInsurance'
import { Edit as EditIcon } from '@material-ui/icons'
import { memo, useMemo } from 'react'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Permissions } from 'containers/Core/utils'
import { useHistory } from 'react-router'
import { RoutePaths } from 'containers/Core/Routes'

interface EligibleInsuranceTableRowProps {
  insurance: Insurance
}

const EligibleInsuranceTableRow: React.FC<EligibleInsuranceTableRowProps> = ({
  insurance,
}) => {
  const history = useHistory()
  const { permissions } = useAuthContext()

  const states = useMemo(
    () => insurance.states.sort((a, b) => (a.code <= b.code ? -1 : 1)),
    [insurance.states]
  )

  const onEdit = () =>
    history.push(
      `${RoutePaths.DASHBOARD_ELIGIBLE_INSURANCES}/${insurance.provider}`
    )

  return (
    <>
      <TableRow key={insurance.provider}>
        <TableCell>{insurance.title}</TableCell>
        <TableCell>{insurance.provider}</TableCell>
        <TableCell>
          {states.map((state) => (
            <Chip
              key={`${insurance.provider}-${state.code}`}
              className="table-chip"
              label={state.code}
            />
          ))}
        </TableCell>
        <TableCell align="right">
          {permissions.includes(Permissions.UPDATE_ELIGIBLE_INSURANCES) && (
            <IconButton
              id={insurance.provider}
              className="list-button"
              size="small"
              onClick={onEdit}
            >
              <EditIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

export default memo(EligibleInsuranceTableRow)

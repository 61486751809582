import Container from 'react-bootstrap/Container'
import { BrowserRouter as Router } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AlertProvider } from 'context/AlertContext/AlertContextProvider'
import { AuthProvider } from 'context/AuthContext/AuthProvider'
import { InfoAlertProvider } from 'context/InfoAlertContext/InfoAlertContextProvider'

import ErrorBoundary from './ErrorBoundary'
import Routes from './Routes'

import '../../theme/main.scss'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 1000 * 60 * 30, // 30 min
    },
  },
})

function App(): JSX.Element {
  return (
    <Router>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      >
        <ErrorBoundary>
          <AlertProvider>
            <InfoAlertProvider>
              <AuthProvider>
                <QueryClientProvider client={queryClient}>
                  <Container fluid className="Container">
                    <Routes />
                  </Container>
                </QueryClientProvider>
              </AuthProvider>
            </InfoAlertProvider>
          </AlertProvider>
        </ErrorBoundary>
      </Auth0Provider>
    </Router>
  )
}

export default App

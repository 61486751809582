import {
  Patients as PatientsRoute,
  PatientsCSV as PatientsCSVRoute,
} from 'api/admin/routes'
import { PatientSearchDto } from 'containers/Dashboard/types'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { getAxiosConfig, QUERY_KEYS } from 'utils/api'
import { useAlertContext } from 'context/AlertContext'
import { useAuth0 } from '@auth0/auth0-react'

interface UsePatientsInterface {
  isLoading: boolean
  error: unknown
  patients: PatientSearchDto[]
  downloadPatientCSV: () => Promise<string | null>
}

const usePatients = (): UsePatientsInterface => {
  const { setAlertText } = useAlertContext()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const fetchPatients = async (): Promise<PatientSearchDto[]> => {
    if (!isAuthenticated) {
      return []
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${PatientsRoute}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          setAlertText(`Error loading patients (${res.status})`)
          return []
        }
      })
      .catch(() => {
        setAlertText('Error loading patients')
        return []
      })
  }

  const { isLoading, error, data } = useQuery(
    QUERY_KEYS.PATIENTS,
    fetchPatients
  )

  const patients = useMemo(() => data ?? [], [data])

  const downloadPatientCSV = async (): Promise<string | null> => {
    if (!isAuthenticated) {
      return null
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${PatientsCSVRoute}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        if (!res.ok) {
          setAlertText(`Error downloading CSV (${res.status})`)
          return null
        }

        return res.text()
      })
      .catch(() => {
        setAlertText('Error downloading CSV')
        return null
      })
  }

  return {
    isLoading,
    error,
    patients,
    downloadPatientCSV,
  }
}

export default usePatients

import { Provider } from 'containers/Dashboard/types'
import { IconButton, TableCell, TableRow } from '@mui/material'
import React, { memo, useState } from 'react'
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  OpenInNew as OpenIcon,
  PowerSettingsNew as PowerIcon,
} from '@material-ui/icons'
import ConfirmDelete from 'components/ConfirmDialog'
import useProviders from 'hooks/useProviders'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Permissions } from 'containers/Core/utils'
import { useHistory } from 'react-router'
import { RoutePaths } from 'containers/Core/Routes'

interface ProviderTableRowProps {
  provider: Provider
}

const ProviderTableRow: React.FC<ProviderTableRowProps> = ({ provider }) => {
  const history = useHistory()
  const { permissions } = useAuthContext()
  const { handleUpdateProvider } = useProviders()
  const [activate, setActivate] = useState<boolean>(true)
  const [confirmText, setConfirmText] = useState<string | null>(null)

  const badgeClass = provider.isActive
    ? 'status-badge-active'
    : 'status-badge-inactive'

  const openConfirmActivateDialog = () => openConfirmDialog(true)
  const openConfirmDeactivateDialog = () => openConfirmDialog(false)
  const closeConfirmDialog = () => setConfirmText(null)
  const handleActivate = () =>
    handleUpdateProvider({ ...provider, isActive: activate })
  const viewProvider = () =>
    history.push(`${RoutePaths.DASHBOARD_PROVIDERS}/${provider.id}`)

  const openConfirmDialog = (activate: boolean) => {
    setActivate(activate)
    setConfirmText(`provider ${provider.firstName} ${provider.lastName}`)
  }

  return (
    <>
      <TableRow key={provider.id}>
        <TableCell>
          <div className="d-flex align-items-center">
            <span className={`status-badge ${badgeClass}`}></span>
            {provider.firstName} {provider.lastName}
          </div>
        </TableCell>
        <TableCell>{provider.email}</TableCell>
        <TableCell>{provider.credentials}</TableCell>
        <TableCell>{provider.type}</TableCell>
        <TableCell align="right">
          <IconButton
            id={provider.id}
            className="list-button"
            size="small"
            href={`mailto:${provider.email}`}
          >
            <EmailIcon />
          </IconButton>
          {permissions.includes(Permissions.UPDATE_PROVIDERS) && (
            <IconButton
              id={provider.id}
              className="list-button"
              size="small"
              onClick={viewProvider}
            >
              <EditIcon />
            </IconButton>
          )}
          {!permissions.includes(Permissions.UPDATE_PROVIDERS) && (
            <IconButton
              id={provider.id}
              className="list-button"
              size="small"
              onClick={viewProvider}
            >
              <OpenIcon />
            </IconButton>
          )}
          {permissions.includes(Permissions.DELETE_PROVIDERS) &&
            provider.isActive && (
              <IconButton
                className="list-button"
                size="small"
                onClick={openConfirmDeactivateDialog}
              >
                <DeleteIcon />
              </IconButton>
            )}
          {permissions.includes(Permissions.DELETE_PROVIDERS) &&
            !provider.isActive && (
              <IconButton
                className="list-button"
                size="small"
                onClick={openConfirmActivateDialog}
              >
                <PowerIcon />
              </IconButton>
            )}
        </TableCell>
      </TableRow>
      <ConfirmDelete
        key={`${activate}`}
        open={confirmText !== null}
        text={`Are you sure you want to ${
          activate ? 'activate' : 'deactivate'
        } ${confirmText}?`}
        titleText={`Confirm ${
          activate ? 'Re-activate' : 'Deactivate'
        } Provider?`}
        helperText={
          activate
            ? undefined
            : 'Note: this will not delete the provider but marks them as inactive. Ensure they are disabled in Source (remove bookable group)'
        }
        onConfirm={handleActivate}
        handleClose={closeConfirmDialog}
      />
    </>
  )
}

export default memo(ProviderTableRow)

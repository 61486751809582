import { InputAdornment, TextField } from '@mui/material'
import { Search as SearchIcon } from '@material-ui/icons'
import { RoutePaths } from 'containers/Core/Routes'
import { PatientSearchDto } from 'containers/Dashboard/types'
import { useSearchContext } from 'context/SearchContext/SearchProvider'
import usePatients from 'hooks/usePatients'
import { memo, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import './styles.scss'

const PatientSearchBar: React.FC = () => {
  const history = useHistory()
  const { patients } = usePatients()
  const { searchText, matchSearch, setSearchText } = useSearchContext()
  const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(event.target.value)

  const handleOnFocus = () => setShowAutoComplete(true)
  const handleOnBlur = () => setShowAutoComplete(false)

  const handleSelectPatient = useCallback((id: string) => {
    setShowAutoComplete(false)
    history.push(`${RoutePaths.DASHBOARD_PATIENTS}/${id}`)
  }, [])

  const filteredPatients = useMemo(() => {
    if (!searchText) {
      return []
    }

    return patients.filter((p) => {
      const keywords = [
        `${p.firstName}${p.lastName}`,
        `${p.preferredName}${p.lastName}`,
        p.email,
        `${p.firstName?.[0]}${p.lastName?.[0]}`,
      ]
      return matchSearch(keywords)
    })
  }, [patients, searchText])

  const sortedPatients = useMemo(() => {
    const sortedActive = filteredPatients
      .filter((p) => p.active)
      .sort((a, b) =>
        `${a.firstName}${a.lastName}` < `${b.firstName}${b.lastName}` ? -1 : 1
      )
    const sortedInactive = filteredPatients
      .filter((p) => !p.active)
      .sort((a, b) =>
        `${a.firstName}${a.lastName}` < `${b.firstName}${b.lastName}` ? -1 : 1
      )
    return [...sortedActive, ...sortedInactive]
  }, [filteredPatients])

  return (
    <>
      <TextField
        value={searchText}
        placeholder="Type a keyword..."
        onChange={handleChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize={'large'} />
            </InputAdornment>
          ),
          classes: { root: 'search' },
          disableUnderline: true,
        }}
        fullWidth
      />
      {showAutoComplete === true && sortedPatients.length > 0 && (
        <div className="auto-complete auto-complete-container position-absolute">
          {sortedPatients.map((patient) => (
            <AutoCompletePatient
              key={patient.id}
              patient={patient}
              onSelect={handleSelectPatient}
            />
          ))}
        </div>
      )}
    </>
  )
}

interface AutoCompletePatientProps {
  patient: PatientSearchDto
  onSelect: (id: string) => void
}

const AutoCompletePatient: React.FC<AutoCompletePatientProps> = ({
  patient,
  onSelect,
}) => {
  const statusClass = patient.active ? 'status-active' : 'status-inactive'
  const statusBadgeClass = patient.active
    ? 'status-badge-active'
    : 'status-badge-inactive'

  const handleOnSelect = useCallback(() => onSelect(patient.id), [])

  return (
    <div className="auto-complete-patient" onMouseDown={handleOnSelect}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column">
          <p className="fs-6 pb-0 mb-0">
            {patient.firstName}
            {patient.preferredName ? ` (${patient.preferredName}) ` : ' '}
            {patient.lastName}
          </p>
          <p className="fs-3 pb-0 mb-0">{patient.email}</p>
          {patient.dob && <p className="fs-3 pb-0 mb-0">DOB: {patient.dob}</p>}
        </div>
        <div className="d-flex align-items-center">
          <p className={`status mb-0 pb-0 ${statusClass}`}>
            <strong>
              {patient.active ? 'Account Active' : 'Account Deactivated'}
            </strong>
          </p>
          <div className={`status-badge ${statusBadgeClass}`} />
        </div>
      </div>
    </div>
  )
}

export default memo(PatientSearchBar)

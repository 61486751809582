import { Provider } from 'containers/Dashboard/types'
import {
  Button,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchContext } from 'context/SearchContext/SearchProvider'
import { Add as AddIcon } from '@material-ui/icons'
import useProviders from 'hooks/useProviders'
import NewProviderDialog from './NewProviderDialog'
import ProviderTableRow from './ProviderTableRow'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Permissions } from 'containers/Core/utils'
import { alphabetize } from 'utils/sort'
import { useHistory, useParams } from 'react-router'
import { useAlertContext } from 'context/AlertContext'
import { RoutePaths } from 'containers/Core/Routes'
import ProviderDialog from 'components/ProviderDialog'

interface ProvidersParams {
  providerId?: string
}

const ProvidersList: React.FC = () => {
  const history = useHistory()
  const { providers } = useProviders()
  const { permissions } = useAuthContext()
  const { setAlertText } = useAlertContext()
  const { providerId } = useParams<ProvidersParams>()
  const { searchText, matchSearch, resetSearchText } = useSearchContext()
  const [openAddNew, setOpenAddNew] = useState<boolean>(false)
  const [editProvider, setEditProvider] = useState<Provider | null>(null)
  const [showDeactivated, setShowDeactivated] = useState<boolean>(false)

  const openAddNewDialog = () => setOpenAddNew(true)
  const closeAddNewDialog = () => setOpenAddNew(false)
  const closeProviderDialog = () => history.push(RoutePaths.DASHBOARD_PROVIDERS)
  const toggleShowDeactivated = () => setShowDeactivated((current) => !current)

  useEffect(() => {
    if (providers.length && providerId) {
      const provider = providers.find((p) => p.id === providerId)

      if (provider) {
        setEditProvider(provider)
      } else {
        setAlertText(`Unknown provider ${providerId}`)
        history.push(RoutePaths.DASHBOARD_PROVIDERS)
      }
    } else if (!providerId) {
      setEditProvider(null)
    }
  }, [providers, providerId])

  const filteredProviders = useMemo(() => {
    if (!searchText) {
      return providers
    }

    return providers.filter((p) => {
      if (!showDeactivated && !p.isActive) {
        return false
      }

      const keywords = [
        `${p.firstName} ${p.lastName}`,
        p.email,
        p.credentials,
        p.type,
      ]
      return matchSearch(keywords)
    })
  }, [providers, searchText, showDeactivated])

  const sortedProviders = useMemo(() => {
    return filteredProviders.sort((a, b) =>
      alphabetize(
        `${a.firstName} ${a.lastName}`,
        `${b.firstName} ${b.lastName}`
      )
    )
  }, [filteredProviders])

  return (
    <div>
      <TableContainer className="table-container">
        <div className="d-flex justify-content-end">
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={showDeactivated}
                  onChange={toggleShowDeactivated}
                  name="show-deactivated"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color="primary"
                />
              }
              label="Show Deactivated"
              labelPlacement="start"
            />
          </div>
          <div style={{ width: '10px' }} />
          {permissions.includes(Permissions.ADD_PROVIDERS) && (
            <Button
              variant="contained"
              className="add-new-button"
              startIcon={<AddIcon />}
              onClick={openAddNewDialog}
            >
              Add New
            </Button>
          )}
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Credentials</TableCell>
              <TableCell>Type</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedProviders.map((provider) => (
              <ProviderTableRow key={provider.id} provider={provider} />
            ))}
          </TableBody>
        </Table>
        {searchText && (
          <div className="no-match">
            {!filteredProviders.length && (
              <>
                No matching providers for {`'${searchText}'`}
                <br />
              </>
            )}
            <p className="clear-search" onClick={resetSearchText}>
              Clear Search
            </p>
          </div>
        )}
      </TableContainer>
      <ProviderDialog
        provider={editProvider}
        handleClose={closeProviderDialog}
        readOnly={!permissions.includes(Permissions.UPDATE_PROVIDERS)}
      />
      <NewProviderDialog open={openAddNew} handleClose={closeAddNewDialog} />
    </div>
  )
}

export default ProvidersList

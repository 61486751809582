/**
 * Parse an object into CSV
 * @param data
 * @returns
 */
export function parse(data: Record<string, string>[]): string {
  if (data?.length < 2) {
    return ''
  }

  const fields = Object.keys(data[0])
  const csvData = data.map((row) => fields.map((field) => row[field]).join(','))
  const csvHeader = fields.join(',')
  return [csvHeader, ...csvData].join('\r\n')
}

/**
 * Download a csv string as a CSV file for the user
 * @param data
 * @param name
 */
export function handleDownloadCSV(data: string, name: string): void {
  // Download the string as CSV file
  const a = window.document.createElement('a')
  a.href = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }))
  a.download = `${name}_${new Date().toISOString()}.csv`

  // Append anchor to body.
  document.body.appendChild(a)
  a.click()

  // Remove anchor from body
  document.body.removeChild(a)
}

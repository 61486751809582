import { Button, Typography } from '@mui/material'
import AddModuleDialog from 'components/AddModuleDialog'
import ConciergeActionsTable from 'components/PatientTimelineDialog/ConciergeActionsTable'
import ModuleActionsTable from 'components/PatientTimelineDialog/ModuleActionsTable'
import PatientActionsTable from 'components/PatientTimelineDialog/PatientActionsTable'
import ProviderActionsTable from 'components/PatientTimelineDialog/ProviderActionsTable'
import SystemActionsTable from 'components/PatientTimelineDialog/SystemActionsTable'
import { RoutePaths } from 'containers/Core/Routes'
import { memo, useState } from 'react'
import { useHistory } from 'react-router'
import { Patient } from '../types'
import { Add as AddIcon } from '@material-ui/icons'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { Permissions } from 'containers/Core/utils'

import './styles.scss'

interface PatientTimelineProps {
  patient: Patient
}

const PatientTimeline: React.FC<PatientTimelineProps> = ({ patient }) => {
  const history = useHistory()
  const { permissions } = useAuthContext()
  const [showAddModule, setShowAddModule] = useState<boolean>(false)

  const showAddModuleDialog = () => setShowAddModule(true)
  const hideAddModuleDialog = () => setShowAddModule(false)
  const openPatientView = () =>
    history.push(`${RoutePaths.DASHBOARD_PATIENTS}/${patient.id}`)
  const closePatientView = () => history.push(RoutePaths.DASHBOARD_PATIENTS)

  return (
    <div className="patient-view-container pt-4 pb-6">
      <div className="d-flex align-items-center justify-content-between mx-4">
        <Typography variant="h4" className="patient-name">
          {patient?.firstName}{' '}
          {patient?.preferredName ? `(${patient.preferredName})` : ''}{' '}
          {patient?.lastName}
        </Typography>
        <div className="d-flex">
          <Button variant="contained" onClick={openPatientView}>
            Open Patient View
          </Button>
          {permissions.includes(Permissions.UPDATE_PATIENTS) && (
            <Button
              variant="contained"
              className="button-margin"
              startIcon={<AddIcon />}
              onClick={showAddModuleDialog}
            >
              Add Module
            </Button>
          )}
          <Button
            variant="contained"
            className="cancel-button button-margin"
            onClick={closePatientView}
          >
            Close
          </Button>
        </div>
      </div>
      <PatientActionsTable patientId={patient.id} />
      <ProviderActionsTable patient={patient} />
      <ConciergeActionsTable patientId={patient.id} />
      <SystemActionsTable patientId={patient.id} />
      <ModuleActionsTable patientId={patient.id} />
      <AddModuleDialog
        show={showAddModule}
        patient={patient}
        handleClose={hideAddModuleDialog}
      />
    </div>
  )
}

export default memo(PatientTimeline)

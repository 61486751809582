import { useAuth0 } from '@auth0/auth0-react'
import {
  AppBar,
  Divider,
  Drawer,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Toolbar,
} from '@mui/material'
import {
  AddLocation as AddLocationIcon,
  ExitToApp as LogoutIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
  CalendarToday as CalendarIcon,
} from '@material-ui/icons'
import AddStateDialog from 'components/AddStateDialog'
import AvailabilityDialog from 'components/AvailabilityDialog'
import PatientSearchBar from 'components/PatientSearchBar'
import { RoutePaths } from 'containers/Core/Routes'
import { Permissions } from 'containers/Core/utils'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import { useSearchContext } from 'context/SearchContext/SearchProvider'
import { useMemo, useState } from 'react'
import WaitlistUserStateDialog from 'components/WaitlistUserStateDialog'

import './styles.scss'

const NavBar: React.FC = () => {
  const { logout } = useAuth0()
  const { permissions } = useAuthContext()
  const { searchText, setSearchText } = useSearchContext()
  const [open, setOpen] = useState<boolean>(false)
  const [openAddState, setOpenAddState] = useState<boolean>(false)
  const [openAvailability, setOpenAvailability] = useState<boolean>(false)
  const [openChangeWaitlistState, setOpenChangeWaitlistState] =
    useState<boolean>(false)

  const isPatientRoute = useMemo(
    () => window.location.pathname.includes(RoutePaths.DASHBOARD_PATIENTS),
    [window.location.pathname]
  )

  const openMenuDrawer = () => setOpen(true)
  const closeMenuDrawer = () => setOpen(false)
  const closeAddStateDialog = () => setOpenAddState(false)
  const closeAvailabilityDialog = () => setOpenAvailability(false)

  const openAddStateDialog = () => {
    setOpenAddState(true)
    closeMenuDrawer()
  }

  const openCheckAvailabilityDialog = () => {
    setOpenAvailability(true)
    closeMenuDrawer()
  }

  const openChangeWaitlistUserStateDialog = () => {
    setOpenChangeWaitlistState(!openChangeWaitlistState)
    closeMenuDrawer()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(event.target.value)

  const handleLogout = () =>
    logout({ returnTo: window.location.origin + RoutePaths.LOGIN })

  return (
    <div>
      <AppBar position="fixed" classes={{ root: 'app-bar' }}>
        <Toolbar className="">
          {isPatientRoute ? (
            <PatientSearchBar />
          ) : (
            <TextField
              value={searchText}
              placeholder="Type a keyword..."
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize={'large'} />
                  </InputAdornment>
                ),
                classes: { root: 'search' },
                disableUnderline: true,
              }}
              fullWidth
            />
          )}
          <MenuIcon
            className="menu-icon"
            fontSize="large"
            onClick={openMenuDrawer}
          />
        </Toolbar>
        <Drawer anchor="right" open={open} onClose={closeMenuDrawer}>
          <div
            role="presentation"
            onClick={closeMenuDrawer}
            onKeyDown={closeMenuDrawer}
            className="menu-drawer"
          >
            <List>
              {permissions.includes(Permissions.UPDATE_ELIGIBLE_STATES) && (
                <ListItem button onClick={openAddStateDialog}>
                  <ListItemIcon>
                    <AddLocationIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add State" />
                </ListItem>
              )}
              {permissions.includes(Permissions.READ_ANALYTICS) && (
                <ListItem button onClick={openCheckAvailabilityDialog}>
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Check Availability" />
                </ListItem>
              )}
              {permissions.includes(Permissions.READ_WAITLIST_USERS) && (
                <ListItem button onClick={openChangeWaitlistUserStateDialog}>
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Change Waitlist User State" />
                </ListItem>
              )}
              <Divider />
              <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </AppBar>
      <AddStateDialog open={openAddState} handleClose={closeAddStateDialog} />
      <AvailabilityDialog
        open={openAvailability}
        handleClose={closeAvailabilityDialog}
      />
      <WaitlistUserStateDialog
        open={openChangeWaitlistState}
        handleClose={openChangeWaitlistUserStateDialog}
      />
    </div>
  )
}

export default NavBar

import { useAuth0 } from '@auth0/auth0-react'
import { createContext, useContext, useEffect, useState } from 'react'
import { Buffer } from 'buffer'

interface AuthContextInterface {
  accessToken: string
  permissions: string[]
}

export const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
)

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState<string>('')
  const [permissions, setPermissions] = useState<string[]>([])

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      const rawPayload = token.split('.')[1]
      const buf = Buffer.from(rawPayload, 'base64')
      const payload = JSON.parse(buf.toString())
      setPermissions(payload.permissions ?? [])
      setAccessToken(token)
    })
  }, [])

  return (
    <AuthContext.Provider value={{ accessToken, permissions }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = (): AuthContextInterface =>
  useContext(AuthContext)

import { Analytics as AnalyticsRoute } from 'api/admin/routes'
import { AppointmentType } from 'containers/Dashboard/types'
import { useQuery } from 'react-query'
import { getAxiosConfig, QUERY_KEYS } from 'utils/api'
import { useAuth0 } from '@auth0/auth0-react'
import { useMemo } from 'react'

interface UseAppointmentTypesInterface {
  isLoading: boolean
  error: unknown
  appointmentTypes: AppointmentType[]
}

const useAppointmentTypes = (): UseAppointmentTypesInterface => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const fetchAppointmentTypes = async (): Promise<AppointmentType[]> => {
    if (!isAuthenticated) {
      return []
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${process.env.REACT_APP_SERVER_URL}${AnalyticsRoute}/appointment-types`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json()
        } else {
          return
        }
      })
      .catch(() => {
        return
      })
  }

  const { isLoading, error, data } = useQuery(
    [QUERY_KEYS.APPOINTMENT_TYPES],
    fetchAppointmentTypes
  )

  const appointmentTypes = useMemo(() => data ?? [], [data])

  return {
    isLoading,
    error,
    appointmentTypes,
  }
}

export default useAppointmentTypes

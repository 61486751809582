import { useAuth0 } from '@auth0/auth0-react'
import { Analytics as AnalyticsRoute } from 'api/admin/routes'
import { AnalyticsData } from 'containers/Dashboard/Analytics/types'
import { useAlertContext } from 'context/AlertContext'
import { endOfMonth, startOfMonth } from 'date-fns'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { getAxiosConfig, QUERY_KEYS } from 'utils/api'

interface UseAnalyticsDataInterface {
  endDate: Date | null
  startDate: Date | null
  analyticsData?: AnalyticsData
  setEndDate: (date: Date | null) => void
  setStartDate: (date: Date | null) => void
}

const useAnalyticsData = (): UseAnalyticsDataInterface => {
  const queryClient = useQueryClient()
  const { setAlertText } = useAlertContext()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [endDate, setEndDate] = useState<Date | null>(endOfMonth(new Date()))
  const [startDate, setStartDate] = useState<Date | null>(
    startOfMonth(new Date())
  )

  const fetchAnalyticsData = async (): Promise<AnalyticsData> => {
    if (!isAuthenticated || !startDate || !endDate) {
      return {} as AnalyticsData
    }

    const accessToken = await getAccessTokenSilently()
    return fetch(
      `${
        process.env.REACT_APP_SERVER_URL
      }${AnalyticsRoute}?start=${startDate.getTime()}&end=${endDate.getTime()}`,
      getAxiosConfig(accessToken)
    )
      .then(async (res) => {
        const data = await res.json()

        if (res.ok) {
          return data
        } else {
          setAlertText(`Error loading analytics data (${res.status})`)
          return
        }
      })
      .catch(() => {
        setAlertText(`Error loading analytics data`)
        return
      })
  }

  const { data: analyticsData } = useQuery(
    [QUERY_KEYS.ANALYTICS, startDate, endDate],
    fetchAnalyticsData,
    {
      staleTime: Infinity,
    }
  )

  useEffect(() => {
    queryClient.invalidateQueries(QUERY_KEYS.ANALYTICS)
  }, [startDate, endDate])

  return {
    endDate,
    startDate,
    analyticsData,
    setEndDate,
    setStartDate,
  }
}

export default useAnalyticsData

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material'
import { Close as CloseIcon, GetApp as DownloadIcon } from '@material-ui/icons'
import { subWeeks } from 'date-fns'
import { memo, useState } from 'react'
import { useAuthContext } from 'context/AuthContext/AuthProvider'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useAlertContext } from 'context/AlertContext'
import { handleDownloadCSV as downloadDataCSV } from 'utils/csv'

interface ExportTimeFrameDataDialog {
  open: boolean
  name: string
  filename?: string
  subtitle?: string
  permissions?: string[]
  toggle?: {
    label: string
    values: { enabled: string | boolean; disabled: string | boolean }
  }
  handleClose: () => void
  downloadCSV: (
    startDate: Date,
    endDate: Date,
    include?: string | boolean
  ) => Promise<string>
}

const ExportAppointmentsDialog: React.FC<ExportTimeFrameDataDialog> = ({
  open,
  name,
  toggle,
  filename,
  subtitle,
  permissions,
  handleClose,
  downloadCSV,
}) => {
  const now = new Date()
  const { permissions: userPermissions } = useAuthContext()
  const { setAlertText } = useAlertContext()
  const [endDate, setEndDate] = useState<Date>(now)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date>(subWeeks(now, 1))
  const [isTimeTraveler, setIsTimeTraveler] = useState<boolean>(false)
  const [toggleIsEnabled, setToggleIsEnabled] = useState<boolean>(false)

  const handleEndDateChange = (date: Date | null) =>
    date && setDate(date, 'end')

  const handleStartDateChange = (date: Date | null) =>
    date && setDate(date, 'start')

  const handleToggle = () => setToggleIsEnabled((prev) => !prev)

  const setDate = (newDate: Date, type: 'start' | 'end') => {
    setIsTimeTraveler(false)

    if (startDate > endDate) {
      const otherDate = type === 'start' ? 'end' : 'start'
      setIsTimeTraveler(true)
      setAlertText(
        `${type.toLocaleUpperCase()} date cannot be ${
          type === 'start' ? 'after' : 'before'
        } ${otherDate} date`
      )
    } else if (type === 'start') {
      setStartDate(newDate)
    } else {
      setEndDate(newDate)
    }
  }

  const handleDownloadCSV = async () => {
    setIsLoading(true)
    const toggleValue = toggleIsEnabled
      ? toggle?.values.enabled
      : toggle?.values.disabled
    const csvString = await downloadCSV(startDate, endDate, toggleValue)
    downloadDataCSV(csvString, filename ?? 'allara_data')
    setIsLoading(false)
  }

  if (permissions && permissions.find((p) => !userPermissions.includes(p))) {
    return <></>
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="download-time-frame-data-dialog-title"
      fullWidth
      maxWidth="md"
      className="download-time-frame-data-dialog"
    >
      <DialogTitle id="download-time-frame-data-dialog-title">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="mb-5">
        <Typography variant="h4" className="title">
          Please select the date range for {name} you would like to download,
          then click the button below to export.
        </Typography>
        {subtitle && <p>{subtitle}</p>}
        {isTimeTraveler && (
          <>
            <Typography variant="h6">
              Woah! Those dates you tried to select were pretty wonky. Make sure
              the start date is always before the end date.
            </Typography>
            <img src="https://imgs.xkcd.com/comics/time_travel.png" />
          </>
        )}
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ marginRight: '15px' }}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-start"
                label="Starting Date"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{ 'aria-label': 'change-date' }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-end"
                label="Ending Date"
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{ 'aria-label': 'change-date' }}
              />
            </MuiPickersUtilsProvider>
          </div>
          {toggle && (
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={toggleIsEnabled}
                    onChange={handleToggle}
                    name={toggle.label}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    color="primary"
                  />
                }
                label={toggle.label}
                labelPlacement="start"
              />
            </div>
          )}
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleDownloadCSV}
            disabled={isLoading}
          >
            {isLoading ? 'Downloading CSV...' : 'Download CSV'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default memo(ExportAppointmentsDialog)

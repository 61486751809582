import { Container } from 'react-bootstrap'
import AppLogo from 'components/AppLogo'

const PageNotFound: React.FC = () => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center height-full">
      <AppLogo className="position-absolute top-3 " />
      <p className="h1 text-center text-danger pb-6">
        Oops! The page you’re looking for was not found.
      </p>
    </Container>
  )
}

export default PageNotFound

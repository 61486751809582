export const BgImageClassName = {
  MOBILE: 'd-block d-sm-none position-absolute ',
  DESKTOP: 'd-none d-sm-block position-absolute ',
}

export enum Permissions {
  READ_PATIENTS = 'read:patients',
  UPDATE_PATIENTS = 'update:patients',
  DOWNLOAD_PATIENTS = 'download:patients',
  READ_PROVIDERS = 'read:providers',
  UPDATE_PROVIDERS = 'update:providers',
  DELETE_PROVIDERS = 'delete:providers',
  ADD_PROVIDERS = 'add:providers',
  READ_ELIGIBLE_INSURANCES = 'read:eligible-insurances',
  UPDATE_ELIGIBLE_INSURANCES = 'update:eligible-insurances',
  ADD_ELIGIBLE_INSURANCES = 'add:eligible-insurances',
  READ_ANALYTICS = 'read:analytics',
  UPDATE_ELIGIBLE_STATES = 'update:eligible-states',
  READ_WAITLIST_USERS = 'read:waitlist-users',
  UPDATE_WAITLIST_USERS = 'update:waitlist-users',
}
